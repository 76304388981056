import { memo, useEffect } from 'react'

import { captureException } from '@sentry/react'
import { useRouteError } from 'react-router-dom'

import { ErrorDisplay } from '@shared/components/ErrorHandling'

const RouterErrorBoundaryRaw = () => {
  const error = useRouteError()

  useEffect(() => {
    if (error) {
      captureException(error)
    }
  }, [error])

  return <ErrorDisplay error={error as Error} />
}

export const RouterErrorBoundary = memo(RouterErrorBoundaryRaw)
