import { use } from 'i18next'
import { initReactI18next } from 'react-i18next'

import DE_common from 'locales/de/common.json'
import DE_cp from 'locales/de/cp.json'
import DE_domain from 'locales/de/domain.json'
import DE_entity from 'locales/de/entity.json'
import DE_pages from 'locales/de/pages.json'
import EN_common from 'locales/en/common.json'
import EN_cp from 'locales/en/cp.json'
import EN_domain from 'locales/en/domain.json'
import EN_entity from 'locales/en/entity.json'
import EN_pages from 'locales/en/pages.json'

export const defaultNS = 'common'
const resources = {
  en: {
    common: EN_common,
    entity: EN_entity,
    cp: EN_cp,
    domain: EN_domain,
    pages: EN_pages,
  },
  de: {
    common: DE_common,
    entity: DE_entity,
    cp: DE_cp,
    domain: DE_domain,
    pages: DE_pages,
  },
}

// use(LanguageDetector)
use(initReactI18next).init({
  lng: 'en',
  ns: ['common', 'entity', 'domain', 'pages'],
  defaultNS,
  fallbackLng: 'en',
  resources,
  interpolation: {
    defaultVariables: { id: '' },
    escapeValue: false,
    skipOnVariables: false,
    format: (value: number, format?: string, lng?: Intl.LocalesArgument) => {
      if (format === 'timestamp') {
        const options = {
          weekday: 'long' as const,
          year: 'numeric' as const,
          month: 'long' as const,
          day: 'numeric' as const,
        }
        return new Date(value * 1000).toLocaleDateString(lng, options)
      }
      return value.toString()
    },
  },
})
