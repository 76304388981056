import { ForwardedRef, forwardRef, memo } from 'react'

import { styled, Typography, TypographyProps, SxProps, Theme } from '@mui/material'

import { ThemeTypes } from 'stores/settings'

const Root = styled(Typography)(({ theme }) => ({
  fontFamily: '"Fira Code", monospace',
  display: 'inline-block',
  padding: theme.spacing(0.3, 0.5),
  margin: 0,
  backgroundColor: theme.palette.mode === ThemeTypes.LIGHT ? theme.palette.grey[200] : theme.palette.grey[800],
  borderRadius: 4,
})) as typeof Typography

type CodeInlineProps = {
  sx?: SxProps<Theme>
} & Omit<TypographyProps<'code'>, 'sx'>

const CodeInlineRaw = (
  { children, sx = {}, ...overrideProps }: CodeInlineProps,
  forwardedRef: ForwardedRef<HTMLPreElement>,
) => {
  return (
    <Root
      sx={[...(Array.isArray(sx) ? sx : [sx])]}
      ref={forwardedRef}
      component="code"
      variant="text-sm"
      {...overrideProps}
    >
      {children}
    </Root>
  )
}

export const CodeInline = memo(forwardRef(CodeInlineRaw))
