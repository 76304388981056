import { lazy, Suspense, useCallback } from 'react'

import { upperFirst } from 'lodash-es'
import { useSnackbar } from 'notistack'
import { createBrowserRouter, createRoutesFromElements, Outlet, Route } from 'react-router-dom'
import { shallow } from 'zustand/shallow'

import { RouterErrorBoundary } from '@shared/components/ErrorHandling'
import { SnackbarProvider } from '@shared/components/Snackbar'
import { Structure } from '@shared/layouts'
import { DynamicRouteParameterWPrefix, StaticRouteParameter } from 'config/routing'
import { RedirectToProcessInvitation, Root as InvitationRouting } from 'domains/ProcessInvitation'
import { useHiddenActivator, useServiceWorker } from 'hooks/utils'
import { RequireAuth, AuthenticationManager, AzureB2CADInitializer } from 'pages/AppInit/Auth'
import { AssociatedSentryInit } from 'pages/AppInit/Logging'
import { selectNotificationSetters, useNotificationStore } from 'stores/notifications'
import { selectSettingsSetters, selectTheme, ThemeTypes, useSettingsStore } from 'stores/settings'

const NWPlatform = lazy(() => import('pages/NWPlatform'))

const App = () => {
  const { enqueueSnackbar } = useSnackbar()

  const { pushNotification } = useNotificationStore(selectNotificationSetters, shallow)
  const theme = useSettingsStore(selectTheme)
  const { setTheme } = useSettingsStore(selectSettingsSetters)

  useServiceWorker()

  useHiddenActivator(
    'pnot',
    useCallback(() => {
      pushNotification({
        id: 'pnot',
        title: 'Easter egg',
        description:
          'This notification has been triggered by a hidden activator. To dismiss, please resolve the notification.',
      })
    }, [pushNotification]),
  )

  useHiddenActivator(
    'theme',
    useCallback(() => {
      const themeToSet = theme === ThemeTypes.LIGHT ? ThemeTypes.DARK : ThemeTypes.LIGHT

      setTheme(themeToSet)
      enqueueSnackbar(`Switched to ${upperFirst(themeToSet)} theme`, { variant: 'info' })
    }, [enqueueSnackbar, setTheme, theme]),
  )

  return (
    <SnackbarProvider>
      <Outlet />
    </SnackbarProvider>
  )
}

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<App />} errorElement={<RouterErrorBoundary />}>
      <Route element={<AzureB2CADInitializer />}>
        {/* START: Public routes */}
        <Route
          path={`/${StaticRouteParameter.MY}/${StaticRouteParameter.ORGANIZATIONS}/${DynamicRouteParameterWPrefix.ORGANIZATION_ID}/${StaticRouteParameter.ACCEPT_INVITATION}`}
          element={<RedirectToProcessInvitation />}
        />
        <Route
          path={`/${StaticRouteParameter.ENVIRONMENTS}/${DynamicRouteParameterWPrefix.ENVIRONMENT_ID}/${StaticRouteParameter.ACCEPT_INVITATION}`}
          element={<RedirectToProcessInvitation />}
        />
        <Route
          path={`/${StaticRouteParameter.ENVIRONMENTS}/${DynamicRouteParameterWPrefix.ENVIRONMENT_ID}/${StaticRouteParameter.WORKSPACES}/${DynamicRouteParameterWPrefix.WORKSPACE_ID}/${StaticRouteParameter.ACCEPT_INVITATION}`}
          element={<RedirectToProcessInvitation />}
        />
        <Route path={`/${StaticRouteParameter.PROCESS_INVITATION}`} element={<InvitationRouting />} />
        {/* END: Public routes */}

        {/* START: Private routes*/}
        <Route element={<AuthenticationManager />}>
          <Route element={<RequireAuth />}>
            <Route element={<AssociatedSentryInit />}>
              <Route
                path="*"
                element={
                  <Suspense fallback={<Structure isLoading />}>
                    <NWPlatform />
                  </Suspense>
                }
              />
            </Route>
          </Route>
        </Route>
        {/* END: Private routes */}
      </Route>
    </Route>,
  ),
)
