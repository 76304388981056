import { useEffect } from 'react'

import { useAccessRefreshMutation } from 'hooks/mutations/identity'
import { useAuthenticationStoreV2, selectAuthenticationStoreV2Props } from 'stores/auth'

export const useRequestRefreshAutomation = () => {
  const { accessDenialDelta } = useAuthenticationStoreV2(selectAuthenticationStoreV2Props('accessDenialDelta'))

  const { mutate: requestRefresh } = useAccessRefreshMutation()

  useEffect(() => {
    if (accessDenialDelta && accessDenialDelta > 0) {
      const timeoutHandler = setTimeout(requestRefresh, accessDenialDelta - 5 * 60 * 1000)
      return () => clearTimeout(timeoutHandler)
    }
  }, [accessDenialDelta, requestRefresh])
}
