import { memo, useState } from 'react'

import { BugReport, CopyAll, KeyboardArrowDown, Warning } from '@mui/icons-material'
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Fade,
  IconButton,
  Modal,
  Tooltip,
  Typography,
} from '@mui/material'
import { useSnackbar } from 'notistack'
import CopyToClipboard from 'react-copy-to-clipboard'
import { useTranslation } from 'react-i18next'
import SimpleBar from 'simplebar-react'

import { CodeInline } from '@shared/components/MaterialUIEnhancements/CodeInline'

type ErrorDisplayProps = {
  error?: Error
}

const ErrorDisplayRaw = ({ error }: ErrorDisplayProps) => {
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()

  const [modalOpen, setModalOpen] = useState(!!error)

  return (
    <Modal open={modalOpen} onClose={() => setModalOpen(false)} closeAfterTransition>
      <Fade in={modalOpen} timeout={300} mountOnEnter unmountOnExit>
        <Box sx={{ display: 'flex', width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center' }}>
          <Card sx={{ width: 'auto', minWidth: 500, zIndex: (theme) => theme.zIndex.modal }} raised>
            <CardHeader
              sx={{ backgroundColor: 'background.content' }}
              avatar={error ? <Warning sx={{ color: 'error.main' }} /> : <BugReport sx={{ color: 'warning.main' }} />}
              title="Something has caused the Console to crash"
              titleTypographyProps={{
                variant: 'text',
              }}
              subheaderTypographyProps={{
                variant: 'text-sm',
              }}
              subheader="Please use the error report below if you notify one of the developers"
            />

            {error && (
              <>
                <CardContent sx={{ width: 480 }}>
                  <Typography variant="heading-sm" gutterBottom>
                    What caused it to crash: <CodeInline sx={{ my: 0, py: 0 }}>{error?.name}</CodeInline>
                  </Typography>
                </CardContent>
                <CardActions
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    py: 1,
                    px: 2,
                  }}
                >
                  <Typography variant="heading-sm">Further explanation</Typography>
                  {!!error.message && (
                    <>
                      <KeyboardArrowDown sx={{ mx: 1 }} fontSize="small" />
                      <CopyToClipboard
                        text={error.message}
                        onCopy={() =>
                          enqueueSnackbar(t('copiedValueToClipboard', { value: 'explanation' }), { variant: 'info' })
                        }
                      >
                        <Tooltip title={t('copyToClipboard', { id: 'explanation' })} placement="right" arrow>
                          <IconButton size="small">
                            <CopyAll fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </CopyToClipboard>
                    </>
                  )}
                </CardActions>
                <CardContent sx={{ mx: 1 }}>
                  <SimpleBar style={{ maxHeight: 160 }}>
                    <Typography variant="text-sm" paragraph>
                      {error.message || 'No further information available.'}
                    </Typography>
                  </SimpleBar>
                </CardContent>
              </>
            )}
            <CardContent sx={{ width: 480 }}>
              <Typography variant="heading-xs">
                Once you reported the incidence, please reload the page. Please be aware that it is still possible for
                the error reappear again under the same circumstances as before.
              </Typography>
            </CardContent>

            <CardActions
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                py: 1,
                px: 2,
              }}
            >
              <Button variant="text" size="small" color="warning" onClick={() => setModalOpen(false)}>
                Proceed (unsafe)
              </Button>
              <Button variant="contained" size="small" onClick={() => window.location.reload()}>
                {t('reload')}
              </Button>
            </CardActions>
          </Card>
        </Box>
      </Fade>
    </Modal>
  )
}

export const ErrorDisplay = memo(ErrorDisplayRaw)
