import { memo, useEffect, useState } from 'react'

import { ArrowCircleRight, Home } from '@mui/icons-material'
import { Alert, AlertTitle, Box, Button, Typography } from '@mui/material'
import { ResponseError as OrgResponseError } from '@nativewaves/platform-sdk-browser/org'
import { ResponseError as SmepResponseError } from '@nativewaves/platform-sdk-browser/smep'
import { motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useShallow } from 'zustand/react/shallow'

import { popInOut } from '@shared/utils/support'
import { StaticRouteParameter } from 'config/routing'
import { useInvitationContext } from 'domains/ProcessInvitation/context'
import { useEntityCheck } from 'domains/ProcessInvitation/useEntityCheck'
import { useSignOutMutation } from 'hooks/mutations/azure'
import { useEnvironmentMemberCreateMutation, useOrganizationMemberCreateMutation } from 'hooks/mutations/org'
import { useWorkspaceMemberCreateMutation } from 'hooks/mutations/smep'
import { selectAuthenticationStoreV2Props, useAuthenticationStoreV2 } from 'stores/auth'

const CurrentUserCheckRaw = () => {
  const { t } = useTranslation(['common', 'entity'])

  const invitationContextData = useInvitationContext()
  const { invitationToken, referrer } = invitationContextData

  const { emailAdresses } = useAuthenticationStoreV2(useShallow(selectAuthenticationStoreV2Props('emailAdresses')))

  const [asyncError, setAsyncError] = useState<{ errors?: Record<string, string[]>; statusCode: number }>()
  const [asyncInvitationError, setasyncInvitationError] = useState<{
    errors?: Record<string, string[]>
    statusCode: number
  }>()
  const [userAllowedToRequestAccess, setUserAllowedToRequestAccess] = useState(false)

  const { invitationQuery, resolvedReferrer, type } = useEntityCheck()

  const workspaceMemberCreateMutation = useWorkspaceMemberCreateMutation()
  const { mutate: associateMemberWithWorkspace } = workspaceMemberCreateMutation
  const environmentMemberCreateMutation = useEnvironmentMemberCreateMutation()
  const { mutate: associateMemberWithEnvironment } = environmentMemberCreateMutation
  const organizationMemberCreateMutation = useOrganizationMemberCreateMutation()
  const { mutate: associateMemberWithOrganization } = organizationMemberCreateMutation

  const { mutate: signOut } = useSignOutMutation()

  useEffect(() => {
    if (!invitationQuery.isLoading && invitationQuery.data) {
      const invitation = invitationQuery.data
      if (
        !!invitation.allowMultiUse ||
        (invitation.recipientType === 'Email' && emailAdresses.includes(invitation.recipientEmail!))
      ) {
        setUserAllowedToRequestAccess(true)
      }
    }
  }, [emailAdresses, invitationQuery.data, invitationQuery.isLoading])

  useEffect(
    () =>
      void (async () => {
        if (invitationQuery.isError) {
          if (invitationQuery.error instanceof SmepResponseError || invitationQuery.error instanceof OrgResponseError) {
            const awaitedError: { errors?: Record<string, string[]>; statusCode: number } =
              await invitationQuery.error.response.json()

            setasyncInvitationError(awaitedError)
          }
        }
      })(),
    [invitationQuery.error, invitationQuery.isError],
  )

  useEffect(() => {
    if (userAllowedToRequestAccess) {
      if (type === 'Workspace') {
        associateMemberWithWorkspace(
          {
            workspaceId: resolvedReferrer.params.workspaceId!,
            smepWorkspaceRoleRamMemberInsert: {
              invitationToken,
            },
          },
          {
            onError: async (err) => {
              if (err instanceof SmepResponseError) {
                const awaitedError: { errors?: Record<string, string[]>; statusCode: number } =
                  await err.response.json()

                setAsyncError(awaitedError)
              }
            },
          },
        )
      }
      if (type === 'Environment') {
        associateMemberWithEnvironment(
          {
            environmentId: resolvedReferrer.params.environmentId!,
            orgEnvironmentRoleRamMemberInsert: {
              invitationToken,
            },
          },
          {
            onError: async (err) => {
              if (err instanceof OrgResponseError) {
                const awaitedError: { errors?: Record<string, string[]>; statusCode: number } =
                  await err.response.json()

                setAsyncError(awaitedError)
              }
            },
          },
        )
      }
      if (type === 'Organization') {
        associateMemberWithOrganization(
          {
            organizationId: resolvedReferrer.params.organizationId!,
            organizationRoleRamMemberInsert: {
              invitationToken,
            },
          },
          {
            onError: async (err) => {
              if (err instanceof OrgResponseError) {
                const awaitedError: { errors?: Record<string, string[]>; statusCode: number } =
                  await err.response.json()

                setAsyncError(awaitedError)
              }
            },
          },
        )
      }
    }
  }, [
    associateMemberWithEnvironment,
    associateMemberWithOrganization,
    associateMemberWithWorkspace,
    invitationToken,
    resolvedReferrer.params.environmentId,
    resolvedReferrer.params.organizationId,
    resolvedReferrer.params.workspaceId,
    type,
    userAllowedToRequestAccess,
  ])

  if (asyncError) {
    if (
      workspaceMemberCreateMutation.isSuccess ||
      environmentMemberCreateMutation.isSuccess ||
      organizationMemberCreateMutation.isSuccess
    ) {
      return (
        <Box
          component={motion.div}
          key="invitation-error-but-success"
          variants={popInOut}
          initial="hidden"
          animate="visible"
          exit="hidden"
        >
          <Alert severity="error">
            <AlertTitle>Something went wrong</AlertTitle>
            <Typography component="span" variant="inherit">
              Console was not able to associate you with this{' '}
              {type === 'Workspace'
                ? t('entity:smep.workspace', { count: 1 })
                : t('entity:org.environment', { count: 1 })}
              . Please try again later.
            </Typography>
          </Alert>
        </Box>
      )
    }

    if (asyncError.statusCode === 409) {
      return (
        <Box
          component={motion.div}
          key="invitation-user-associated-already"
          variants={popInOut}
          initial="hidden"
          animate="visible"
          exit="hidden"
        >
          <Typography variant="heading-md">Account associated already</Typography>
          <Typography>
            Account has been associated with this{' '}
            {type === 'Workspace'
              ? t('entity:smep.workspace', { count: 1 })
              : type === 'Environment'
                ? t('entity:org.environment', { count: 1 })
                : t('entity:org.organization', { count: 1 })}{' '}
            already. If you want to change the permission, please consolidate an administrator.
          </Typography>
          <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', justifyContent: 'flex-end', columnGap: 2 }}>
            <Button variant="outlined" endIcon={<ArrowCircleRight />} component={Link} replace to={referrer}>
              Proceed
            </Button>
          </Box>
        </Box>
      )
    }

    if (asyncError.statusCode === 403) {
      return (
        <Box
          component={motion.div}
          key="invitation-error-not-part"
          variants={popInOut}
          initial="hidden"
          animate="visible"
          exit="hidden"
        >
          <Typography variant="heading-md">Invalid Account</Typography>
          <Typography>
            The account which is currently signed in, is not part of the invitation. Therefore please either sign in
            with an account that uses the E-Mail to which this invitation was sent, or sign up with the aforementioned
            E-Mail address.
          </Typography>
          <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', justifyContent: 'flex-end', columnGap: 2 }}>
            <Button
              onClick={() =>
                signOut({
                  postLogoutRedirectUri: `${window.location.origin}/process-invitation`,
                  state: Object.entries(invitationContextData)
                    .map((entry) => entry.join('='))
                    .join('&'),
                })
              }
            >
              Sign-out
            </Button>
            <Button
              variant="outlined"
              endIcon={<Home />}
              component={Link}
              replace
              to={`/${StaticRouteParameter.MY}/${StaticRouteParameter.ORGANIZATIONS}`}
            >
              Return to Home
            </Button>
          </Box>
        </Box>
      )
    }

    return (
      <Box
        component={motion.div}
        key="invitation-error-editor"
        variants={popInOut}
        initial="hidden"
        animate="visible"
        exit="hidden"
      >
        <Alert severity="error">
          <AlertTitle>Something went wrong</AlertTitle>
          <Typography component="span" variant="inherit">
            Console was not able to sign you into the NativeWaves system. Please try again later.
          </Typography>
        </Alert>
      </Box>
    )
  }

  if (asyncInvitationError?.statusCode === 404) {
    return (
      <Box
        sx={{ width: '100%' }}
        component={motion.div}
        key="invitation-invalid"
        variants={popInOut}
        initial="hidden"
        animate="visible"
        exit="hidden"
      >
        <Typography variant="heading-md">Invitation invalid</Typography>
        <Typography>
          The invitation has either expired or has been revoked. Please contact an administrator to resolve this issue.
        </Typography>

        <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', justifyContent: 'flex-end', columnGap: 2 }}>
          <Button variant="outlined" endIcon={<Home />} component={Link} replace to="/">
            Return to Home
          </Button>
        </Box>
      </Box>
    )
  }

  if (
    workspaceMemberCreateMutation.isSuccess ||
    environmentMemberCreateMutation.isSuccess ||
    organizationMemberCreateMutation.isSuccess
  ) {
    return (
      <Box
        component={motion.div}
        key="invitation-success"
        variants={popInOut}
        initial="hidden"
        animate="visible"
        exit="hidden"
      >
        <Typography variant="heading-md">Access granted</Typography>
        <Typography>
          Account was successfully associated and granted access to this{' '}
          {type === 'Workspace'
            ? t('entity:smep.workspace', { count: 1 })
            : type === 'Environment'
              ? t('entity:org.environment', { count: 1 })
              : t('entity:org.organization', { count: 1 })}
          .
        </Typography>
        <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', justifyContent: 'flex-end', columnGap: 2 }}>
          <Button variant="outlined" endIcon={<ArrowCircleRight />} component={Link} replace to={referrer}>
            Proceed
          </Button>
        </Box>
      </Box>
    )
  }

  if (!invitationQuery.isLoading && invitationQuery.data) {
    const invitation = invitationQuery.data
    if (
      !invitation.allowMultiUse &&
      invitation.recipientType === 'Email' &&
      !emailAdresses.includes(invitation.recipientEmail!)
    ) {
      return (
        <Box
          component={motion.div}
          key="invitation-user-not-part-of-this-invitation"
          variants={popInOut}
          initial="hidden"
          animate="visible"
          exit="hidden"
        >
          <Typography variant="heading-md">Invalid Account</Typography>
          <Typography>
            Account is not part of this invitation. Therefore please either sign in with a User that uses the E-Mail{' '}
            <Typography sx={{ fontWeight: 'bold' }} component="span">{`( ${invitation.recipientEmail} )`}</Typography>{' '}
            to which this invitation was sent, or sign up with the aforementioned E-Mail address.
          </Typography>
          <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', justifyContent: 'flex-end', columnGap: 2 }}>
            <Button
              onClick={() =>
                signOut({
                  postLogoutRedirectUri: `${window.location.origin}/process-invitation`,
                  state: Object.entries(invitationContextData)
                    .map((entry) => entry.join('='))
                    .join('&'),
                })
              }
            >
              Sign-out
            </Button>
            <Button
              variant="outlined"
              endIcon={<Home />}
              component={Link}
              replace
              to={`/${StaticRouteParameter.MY}/${StaticRouteParameter.ORGANIZATIONS}`}
            >
              Return to Home
            </Button>
          </Box>
        </Box>
      )
    }
  }

  return (
    <Box
      component={motion.div}
      key="invitation-sign-in-in-progress"
      variants={popInOut}
      initial="hidden"
      animate="visible"
      exit="hidden"
    >
      <Typography variant="heading-base">Completing association...</Typography>
    </Box>
  )
}

export const CurrentUserCheck = memo(CurrentUserCheckRaw)
