import { memo, PropsWithChildren, useMemo } from 'react'

import {
  colors,
  ThemeOptions,
  SimplePaletteColorOptions,
  alpha,
  createTheme,
  ThemeProvider as MuiThemeProvider,
} from '@mui/material'
import { TypographyOptions } from '@mui/material/styles/createTypography'

import { FontTypes, selectFontFamily, selectTheme, ThemeTypes, useSettingsStore } from 'stores/settings'

const lightPrimaryShades = {
  100: '#6693b7',
  300: '#336f9f',
  500: '#004b87',
  700: '#003c6c',
  900: '#002d51',
} as const

const darkPrimaryShades = {
  100: '#66c8ec',
  300: '#33b5e6',
  500: '#00a3e0',
  700: '#0082b3',
  900: '#006286',
} as const

type NWColorPaletteOptions = 'mid-blue' | 'dark-blue' | 'dark-navy' | 'black' | 'white'
const nwColorPalette: Record<NWColorPaletteOptions, SimplePaletteColorOptions> = {
  'mid-blue': {
    main: darkPrimaryShades[500],
    light: darkPrimaryShades[100],
    dark: darkPrimaryShades[900],
    contrastText: '#FFFFFF',
  },
  'dark-blue': {
    main: lightPrimaryShades[500],
    light: lightPrimaryShades[100],
    dark: lightPrimaryShades[900],
    contrastText: '#FFFFFF',
  },
  'dark-navy': {
    main: '#2b344b',
    light: '#555d77',
    dark: '#020d23',
    contrastText: '#FFFFFF',
  },
  black: {
    main: '#000000',
  },
  white: { main: '#FFFFFF' },
}

const blackShades = {
  100: '#202020',
  300: '#1a1a1a',
  500: '#131313',
  700: '#0d0d0d',
  900: '#060606',
} as const

const whiteShades = {
  100: '#fafafa',
  300: '#f5f5f5',
  500: '#f0f0f0',
  700: '#ebebeb',
  900: '#e6e6e6',
} as const

const typography = (fontFamily: CustomBaseSettings['fontFamily'] = FontTypes.ROBOTO): TypographyOptions =>
  ({
    fontFamily: `"${fontFamily}", sans-serif`,
    /* Active variants */
    'heading-xl': {
      fontSize: 32,
      fontWeight: 700,
      lineHeight: 1.2,
    },
    'heading-lg': {
      fontSize: 28,
      fontWeight: 700,
      lineHeight: 1.4,
    },
    'heading-md': {
      fontSize: 20,
      fontWeight: 500,
      lineHeight: 1.3,
    },
    'heading-base': {
      fontSize: 16,
      lineHeight: 1.5,
      fontWeight: 500,
    },
    'heading-sm': {
      fontSize: 14,
      lineHeight: 1.5,
      fontWeight: 500,
    },
    'heading-xs': {
      fontSize: 12,
      fontWeight: 500,
      lineHeight: 2,
    },
    'text-sm': {
      fontSize: 12,
      lineHeight: 1.7,
    },
    text: {
      fontSize: 14,
      lineHeight: 1.5,
    },
    'text-lg': {
      fontSize: 16,
      lineHeight: 1.5,
    },
    caption: {
      fontSize: 12,
      fontWeight: 300,
      lineHeight: 1.2,
    },
    /* End */

    /* Deactivated variants */
    h1: undefined,
    h2: undefined,
    h3: undefined,
    h4: undefined,
    h5: undefined,
    h6: undefined,
    /* End */

    /* These variants need to be activated since they are still being used by internal components, although they are not accessible trough TS */
    subtitle1: {
      fontFamily: `"${fontFamily}", sans-serif`,
      fontSize: 14,
      lineHeight: 1.5,
      fontWeight: 500,
    },
    subtitle2: {
      fontFamily: `"${fontFamily}", sans-serif`,
      fontSize: 12,
      fontWeight: 500,
      lineHeight: 2,
    },
    body1: {
      fontFamily: `"${fontFamily}", sans-serif`,
      fontSize: 14,
      lineHeight: 1.5,
    },
    body2: {
      fontFamily: `"${fontFamily}", sans-serif`,
      fontSize: 12,
      lineHeight: 2,
    },
    button: {
      fontFamily: `"${fontFamily}", sans-serif`,
    },
    /* End */
  }) as const

type CustomBaseSettings = {
  fontFamily: FontTypes
}

export const baseThemeOptions = (customSettings: CustomBaseSettings): ThemeOptions => ({
  typography: typography(customSettings.fontFamily),
  palette: {
    'mid-blue': nwColorPalette['mid-blue'],
    'dark-navy': nwColorPalette['dark-navy'],
    'dark-blue': nwColorPalette['dark-blue'],
    white: nwColorPalette.white,
    black: nwColorPalette.black,
    shades: {
      black: blackShades,
      white: whiteShades,
    },
    success: {
      main: colors.green[500],
    },
    error: {
      main: colors.red[500],
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        body {
          font-family: '${customSettings.fontFamily}', sans-serif;
        }
      `,
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          'heading-xl': 'h1',
          'heading-lg': 'h2',
          'heading-md': 'h3',
          'heading-base': 'h4',
          'heading-sm': 'h5',
          'heading-xs': 'h6',
          'text-sm': 'span',
          text: 'p',
          'text-lg': 'p',
          caption: 'small',
        },
        variant: 'text',
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        elevation: {
          boxShadow: 'none !important',
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        SelectProps: {
          MenuProps: {
            PaperProps: {
              variant: 'outlined',
            },
          },
        },
      },
    },
    MuiMenu: {
      defaultProps: {
        PaperProps: {
          variant: 'outlined',
        },
      },
      styleOverrides: {
        root: {
          '& .MuiBackdrop-root': {
            backdropFilter: 'blur(2px)',
            backgroundColor: alpha(nwColorPalette.black.main, 0.1),
          },
        },
        paper: {
          maxWidth: 320,
        },
      },
    },
    MuiAutocomplete: {
      defaultProps: {
        componentsProps: {
          paper: {
            variant: 'outlined',
          },
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          lineHeight: 1,
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        text: {
          lineHeight: 1,
        },
      },
    },
    MuiDialog: {
      defaultProps: {
        PaperProps: {
          variant: 'outlined',
        },
      },
      styleOverrides: {
        root: {
          '& .MuiBackdrop-root': {
            backdropFilter: 'blur(2px)',
            backgroundColor: alpha(nwColorPalette.black.main, 0.3),
          },
        },
      },
    },
    MuiPopover: {
      defaultProps: {
        PaperProps: {
          variant: 'outlined',
        },
      },
      styleOverrides: {
        root: {
          '& .MuiBackdrop-root': {
            backdropFilter: 'blur(1px)',
            backgroundColor: alpha(nwColorPalette.black.main, 0.1),
          },
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backdropFilter: 'blur(6px)',
          backgroundColor: alpha(nwColorPalette.black.main, 0.1),
        },
      },
    },
  },
})

export const themeModes = {
  [ThemeTypes.LIGHT]: {
    palette: {
      mode: 'light',
      primary: nwColorPalette['dark-blue'],
      background: {
        default: whiteShades[700],
        paper: whiteShades[300],
        content: whiteShades[100],
        levels: whiteShades,
      },
      text: {
        primary: blackShades[900],
        secondary: blackShades[500],
      },
    },
    components: {
      MuiPaper: {
        styleOverrides: {
          elevation: {
            background: whiteShades[300],
          },
        },
      },
    },
  } as const,
  [ThemeTypes.DARK]: {
    palette: {
      mode: 'dark',
      primary: nwColorPalette['mid-blue'],
      background: {
        default: blackShades[700],
        paper: blackShades[300],
        content: blackShades[900],
        levels: blackShades,
      },
      text: {
        primary: whiteShades[100],
        secondary: whiteShades[500],
      },
    },
    components: {
      MuiPaper: {
        styleOverrides: {
          elevation: {
            background: blackShades[300],
          },
        },
      },
    },
  } as const,
}

const ThemeProviderRaw = ({ children }: PropsWithChildren<Record<string, unknown>>) => {
  const selectedTheme = useSettingsStore(selectTheme)
  const selectedFontFamily = useSettingsStore(selectFontFamily)

  const baseThemeOptionsObj = useMemo(() => baseThemeOptions({ fontFamily: selectedFontFamily }), [selectedFontFamily])

  const themeObj = useMemo(
    () => createTheme(themeModes[selectedTheme], baseThemeOptionsObj),
    [baseThemeOptionsObj, selectedTheme],
  )

  return <MuiThemeProvider theme={themeObj}>{children}</MuiThemeProvider>
}

export const ThemeProvider = memo(ThemeProviderRaw)
