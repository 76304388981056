export enum OS {
  MAC_OS = 'macOS',
  WINDOWS = 'windows',
  OTHER = 'other',
}

export const currentOS =
  navigator.userAgent.indexOf('Mac') !== -1
    ? OS.MAC_OS
    : navigator.userAgent.indexOf('Windows') !== -1
      ? OS.WINDOWS
      : OS.OTHER
