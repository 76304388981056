import { memo, PropsWithoutRef } from 'react'

import { styled, SxProps } from '@mui/material'
import { AnimatePresence, motion, Variants } from 'framer-motion'
import { useLocation } from 'react-router-dom'

import { FrozenOutlet } from '@shared/components/GenericOutlets'

const variants: Variants = {
  left: { opacity: 0, transition: { type: 'spring', duration: 0.8 } },
  center: { opacity: 1, transition: { type: 'spring', duration: 0.8 } },
  right: { opacity: 0, transition: { type: 'spring', duration: 0.8 } },
}

const AnimatedTabContent = styled(motion.div)({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  overflow: 'auto',
})

type TabContentProps = {
  AnimatedTabContentSx?: SxProps
}

const TabContentRaw = ({ AnimatedTabContentSx }: PropsWithoutRef<TabContentProps>) => {
  const { pathname } = useLocation()

  return (
    <AnimatePresence mode="popLayout">
      <AnimatedTabContent
        style={{ flex: 1, display: 'flex', flexDirection: 'column', overflow: 'auto' }}
        sx={AnimatedTabContentSx}
        key={pathname}
        variants={variants}
        initial="left"
        animate="center"
        exit="right"
      >
        <FrozenOutlet />
      </AnimatedTabContent>
    </AnimatePresence>
  )
}

export const TabContent = memo(TabContentRaw)
