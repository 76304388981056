import { PropsWithChildren, FormEvent, memo, ReactNode, useRef } from 'react'

import { Save } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Box, ButtonProps, DialogActions, DialogContent, DialogContentText, styled, BoxProps } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { DialogTitle } from '@shared/components/MaterialUIEnhancements'
import { useCloseHandler } from '@shared/components/MaterialUIEnhancements/Dialog'
import { useHotkeys } from '@shared/utils/hooks'
import CreateIllustration from 'assets/undraw/create.svg?react'

const StyledCreateIllustration = styled(CreateIllustration)({})

type DialogFormContentProps = {
  onClose?: () => void
  onSubmit?: (e: FormEvent<HTMLFormElement>) => void
  title?: ReactNode
  description?: string
  SubmitProgressZone?: ReactNode
  isInitialLoading?: boolean
  Illustration?: ReactNode
  SaveButton?: ReactNode
  SaveButtonProps?: ButtonProps
  children?: ReactNode
} & Omit<BoxProps<'form'>, 'title'>

const DialogFormContentRaw = ({
  onSubmit,
  title,
  description,
  isInitialLoading,
  Illustration,
  SaveButton,
  SaveButtonProps,
  children,
  SubmitProgressZone,
  ...BoxProps
}: PropsWithChildren<DialogFormContentProps>) => {
  const { t } = useTranslation()

  const handleClose = useCloseHandler()

  const formRef = useRef<HTMLFormElement>(null)

  useHotkeys([
    ['mod+s', () => formRef.current?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))],
  ])

  return (
    <Box {...BoxProps} ref={formRef} component="form" onSubmit={onSubmit}>
      {typeof title === 'string' ? (
        <DialogTitle onClose={handleClose} closeIconSize="medium">
          {title}
        </DialogTitle>
      ) : (
        title
      )}
      <DialogContent sx={{ pt: 1, pb: 1, display: 'flex', flexDirection: 'column' }}>
        {Illustration === null
          ? null
          : Illustration || <StyledCreateIllustration sx={{ height: 150, mx: 'auto', mb: 2, color: 'primary.main' }} />}
        {description && (
          <DialogContentText sx={{ pb: 1 }} variant="text-lg">
            {description}
          </DialogContentText>
        )}
        {children}
      </DialogContent>
      <DialogActions
        sx={{ pb: 1, px: 3, display: 'flex', height: 80, alignItems: 'center', justifyContent: 'space-between' }}
      >
        {SubmitProgressZone}
        {SaveButton || (
          <LoadingButton
            sx={{ ml: 'auto' }}
            startIcon={<Save />}
            loading={isInitialLoading}
            loadingPosition="start"
            type="submit"
            variant="text"
            {...SaveButtonProps}
          >
            {t('save')}
          </LoadingButton>
        )}
      </DialogActions>
    </Box>
  )
}

export const DialogFormContent = memo(DialogFormContentRaw)
