import { memo, PropsWithChildren, ReactNode } from 'react'

import { Box, Paper, Typography } from '@mui/material'
import { motion } from 'framer-motion'
import { Outlet } from 'react-router-dom'

import { useSettingsStore, selectSidebarPinned } from 'stores/settings'

const mainVariants = {
  normal: {
    gridTemplateColumns: '40px 1fr',
    transition: {
      delay: 0.3,
    },
  },
  adapted: {
    gridTemplateColumns: '336px 1fr',
  },
}

type StructureProps = {
  SidebarSlot?: ReactNode
  isLoading?: boolean
}

const StructureRaw = ({ SidebarSlot, isLoading, children }: PropsWithChildren<StructureProps>) => {
  const sidebarPinned = useSettingsStore(selectSidebarPinned)

  if (isLoading) {
    return (
      <Box
        sx={{
          minHeight: '100vh',
          width: '100vw',
          my: 0,
          display: 'grid',
          position: 'relative',
          gridTemplateColumns: `${sidebarPinned ? 336 : 40}px 1fr`,
          gridTemplateRows: 'minmax(0, 1fr)',
        }}
      >
        <Paper sx={{ height: '100%', width: sidebarPinned ? 320 : 24 }} elevation={0} />
        <Box
          sx={{
            width: '100%',
            display: 'grid',
            gridTemplateRows: '80px 1fr',
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <Typography variant="text">Initializing...</Typography>
            <Typography variant="heading-xl">NativeWaves Console</Typography>
          </Box>
          <Box sx={{ height: '100%', width: '100%' }} />
        </Box>
      </Box>
    )
  }

  return (
    <Box
      sx={{
        minHeight: '100vh',
        height: '100%',
        width: '100vw',
        my: 0,
        display: 'grid',
        position: 'relative',
        gridTemplateRows: 'minmax(0, 1fr)',
      }}
      component={motion.main}
      variants={mainVariants}
      initial={false}
      animate={sidebarPinned ? 'adapted' : 'normal'}
    >
      {SidebarSlot}
      {children || <Outlet />}
    </Box>
  )
}

export const Structure = memo(StructureRaw)
