import { ForwardedRef, PropsWithChildren, forwardRef, memo } from 'react'

import { Paper, styled } from '@mui/material'

const WidthManagedPaper = styled(Paper)(({ theme }) => ({
  width: '100%',
  minWidth: 320,
  maxWidth: 600,
  overflowY: 'auto',
  maxHeight: `calc(100% - ${theme.spacing(8)})`,
}))

const DialogPaperRaw = ({ children }: PropsWithChildren, ref: ForwardedRef<HTMLDivElement>) => (
  <WidthManagedPaper ref={ref} variant="outlined">
    {children}
  </WidthManagedPaper>
)

export const DialogPaper = memo(forwardRef(DialogPaperRaw))
