import { memo } from 'react'

import { KeyOff, Lock } from '@mui/icons-material'
import { alpha, Avatar, Box, CircularProgress, Paper, styled, Typography } from '@mui/material'
import { ResponseError } from '@nativewaves/platform-sdk-browser/identity'
import { AnimatePresence, motion } from 'framer-motion'

import LogoFull from 'assets/logos/nw-logo_solo.svg?react'

const StyledLogoFull = styled(LogoFull)({})

export type LoadingScreenProps = {
  needsToSignInFirst: boolean
  identityError: ResponseError | null
}

const LoadingScreenRaw = ({ needsToSignInFirst, identityError }: LoadingScreenProps) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', rowGap: 2 }}>
      <Box sx={{ position: 'relative' }} component={motion.div} layout>
        <StyledLogoFull
          sx={{
            width: 64,
            border: '5px solid white',
            borderRadius: '50%',
            boxSizing: 'content-box',
          }}
        />
        <CircularProgress
          sx={[
            {
              position: 'absolute',
              left: 0,
            },
            identityError && { color: (theme) => alpha(theme.palette.error.dark, 0.6) },
          ]}
          size={74}
          color="primary"
          variant={identityError ? 'determinate' : 'indeterminate'}
          value={100}
        />
      </Box>
      <AnimatePresence>
        {needsToSignInFirst && (
          <Box
            sx={{ position: 'relative' }}
            component={motion.div}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { delay: 0.3 } }}
          >
            <Paper sx={{ flex: 1, bgcolor: 'background.levels.100', py: 1, px: 1, maxWidth: 480 }} variant="outlined">
              <Typography variant="heading-base">
                You will be redirected to sign-in into your Account.
                <br />
                Please make sure your browser supports redirection to other websites and doesn&apos;t interrupt the
                flow.
              </Typography>
            </Paper>
          </Box>
        )}
        {!!identityError && (
          <Box
            sx={{ position: 'relative' }}
            component={motion.div}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { delay: 0.3 } }}
          >
            <Paper
              sx={{
                flex: 1,
                bgcolor: 'background.levels.100',
                py: 1,
                px: 1,
                maxWidth: 480,
                display: 'flex',
                alignItems: 'stretch',
                columnGap: 2,
              }}
              variant="outlined"
            >
              <Avatar
                sx={{
                  width: 'auto',
                  px: 1,
                  height: 'auto',
                  justifySelf: 'stretch',
                  bgcolor: 'background.levels.900',
                }}
                variant="rounded"
              >
                <KeyOff fontSize="large" color="error" />
                <Lock fontSize="large" color="error" />
              </Avatar>
              <Box>
                <Typography variant="heading-md">Sign-in attempt unsuccessful</Typography>
                <Typography>
                  Due to an unknown problem, the sign-in process has been aborted. The incidence has been reported and
                  will be investigated.{' '}
                  <Typography component="span" variant="heading-sm">
                    We are sorry for the inconvenience.
                  </Typography>
                </Typography>
              </Box>
            </Paper>
          </Box>
        )}
      </AnimatePresence>
    </Box>
  )
}

export const LoadingScreen = memo(LoadingScreenRaw)
