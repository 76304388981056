import {
  AudioObjectsApi,
  AudioStreamsApi,
  ObjectsApi,
  StreamsApi,
  VideoObjectsApi,
  VideoStreamsApi,
  RepositoriesApi,
  Configuration as ContentConfiguration,
  DataObjectsApi,
  DataStreamsApi,
  ProgramsApi,
  AvcTasksApi,
  AvcInstancesApi,
  ManagedStoresApi,
  ExternalStoresApi,
} from '@nativewaves/platform-sdk-browser/content'
import { AppsApi, Configuration as EXPConfiguration } from '@nativewaves/platform-sdk-browser/exp'
import {
  Configuration as IdentityConfiguration,
  ExternalApi,
  RefreshApi,
} from '@nativewaves/platform-sdk-browser/identity'
import {
  MediaLibrariesApi,
  Configuration as MediaConfiguration,
  MediaChannelsApi,
} from '@nativewaves/platform-sdk-browser/media'
import {
  Configuration as OrgConfiguration,
  EnvironmentsApi,
  OrganizationsApi,
  TeamsApi,
} from '@nativewaves/platform-sdk-browser/org'
import { CollectionsApi, Configuration as ShowcaseConfiguration } from '@nativewaves/platform-sdk-browser/showcase'
import { EventsApi, WorkspacesApi, Configuration as SmepConfiguration } from '@nativewaves/platform-sdk-browser/smep'
import {
  FixturesApi,
  Configuration as SportsdataSoccerConfiguration,
  CompetitionsApi,
  TournamentCalendarsApi,
} from '@nativewaves/platform-sdk-browser/sportsdata/soccer'

import { useAuthenticationStoreV2 } from 'stores/auth'

const platformAccessToken:
  | OrgConfiguration['accessToken']
  | ContentConfiguration['accessToken']
  | SmepConfiguration['accessToken']
  | MediaConfiguration['accessToken']
  | ShowcaseConfiguration['accessToken']
  | SportsdataSoccerConfiguration['accessToken'] = (name?: string) => {
  const accessTokenFromState = useAuthenticationStoreV2.getState().identityAccessToken

  if (!accessTokenFromState) {
    throw new Error('No access token provided.')
  }

  if (name !== 'OAuth2') {
    throw new Error("Only 'OAuth2' token are supported.")
  }

  return `Bearer ${accessTokenFromState}`
}

const ContentAPIConfiguration = new ContentConfiguration({
  accessToken: platformAccessToken,
  basePath: import.meta.env.VITE_NW_API_CONTENT_BASE_PATH,
})
export const ContentAPI = {
  audioObjects: new AudioObjectsApi(ContentAPIConfiguration),
  audioStreams: new AudioStreamsApi(ContentAPIConfiguration),
  avcInstances: new AvcInstancesApi(ContentAPIConfiguration),
  avcTasks: new AvcTasksApi(ContentAPIConfiguration),
  dataobjects: new DataObjectsApi(ContentAPIConfiguration),
  datastreams: new DataStreamsApi(ContentAPIConfiguration),
  externalStores: new ExternalStoresApi(ContentAPIConfiguration),
  managedStores: new ManagedStoresApi(ContentAPIConfiguration),
  objects: new ObjectsApi(ContentAPIConfiguration),
  expManifests: new ProgramsApi(ContentAPIConfiguration),
  streams: new StreamsApi(ContentAPIConfiguration),
  videoObjects: new VideoObjectsApi(ContentAPIConfiguration),
  videoStreams: new VideoStreamsApi(ContentAPIConfiguration),
  repositories: new RepositoriesApi(ContentAPIConfiguration),
}

const ExpAPIConfiguration = new EXPConfiguration({
  accessToken: platformAccessToken,
  basePath: import.meta.env.VITE_NW_API_EXP_BASE_PATH,
})
export const ExpAPI = {
  apps: new AppsApi(ExpAPIConfiguration),
}

const SmepAPIConfiguration = new SmepConfiguration({
  accessToken: platformAccessToken,
  basePath: import.meta.env.VITE_NW_API_SMEP_BASE_PATH,
})
export const SmepAPI = {
  events: new EventsApi(SmepAPIConfiguration),
  workspaces: new WorkspacesApi(SmepAPIConfiguration),
}

const IdentityAPIConfiguration = new IdentityConfiguration({ basePath: import.meta.env.VITE_NW_API_IDENTITY_BASE_PATH })
export const IdentityAPI = {
  external: new ExternalApi(IdentityAPIConfiguration),
  refresh: new RefreshApi(IdentityAPIConfiguration),
}

const OrgAPIConfiguration = new OrgConfiguration({
  accessToken: platformAccessToken,
  basePath: import.meta.env.VITE_NW_API_ORG_BASE_PATH,
})
export const OrgAPI = {
  organizations: new OrganizationsApi(OrgAPIConfiguration),
  environments: new EnvironmentsApi(OrgAPIConfiguration),
  teams: new TeamsApi(OrgAPIConfiguration),
}

const ShowcaseAPIConfiguration = new ShowcaseConfiguration({
  accessToken: platformAccessToken,
  basePath: import.meta.env.VITE_NW_API_SHOWCASE_BASE_PATH,
})
export const ShowcaseAPI = {
  collections: new CollectionsApi(ShowcaseAPIConfiguration),
}

const PublishingAPIConfiguration = new MediaConfiguration({
  accessToken: platformAccessToken,
  basePath: import.meta.env.VITE_NW_API_PUBLISHING_BASE_PATH,
})
export const PublishingAPI = {
  mediaLibraries: new MediaLibrariesApi(PublishingAPIConfiguration),
  mediaChannels: new MediaChannelsApi(PublishingAPIConfiguration),
}

const SportsdataAPIConfiguration = new SportsdataSoccerConfiguration({
  accessToken: platformAccessToken,
  basePath: import.meta.env.VITE_NW_API_SPORTSDATA_BASE_PATH,
})
export const SportsdataAPI = {
  performSoccer: {
    competitions: new CompetitionsApi(SportsdataAPIConfiguration),
    tournamentCalendars: new TournamentCalendarsApi(SportsdataAPIConfiguration),
    fixtures: new FixturesApi(SportsdataAPIConfiguration),
  },
}
