import { ResponseError as ContentResponseError } from '@nativewaves/platform-sdk-browser/content'
import { ResponseError as ExpResponseError } from '@nativewaves/platform-sdk-browser/exp'
import { ResponseError as MediaResponseError } from '@nativewaves/platform-sdk-browser/media'
import { ResponseError as OrgResponseError } from '@nativewaves/platform-sdk-browser/org'
import { ResponseError as ShowcaseResponseError } from '@nativewaves/platform-sdk-browser/showcase'
import { ResponseError as SmepResponseError } from '@nativewaves/platform-sdk-browser/smep'
import { captureException } from '@sentry/react'
import { QueryKey, QueryClient, QueryCache, MutationCache } from '@tanstack/react-query'

import { IdentityAPI } from 'services/api'
import { activeMsalVersion } from 'setup/azureB2C'
import { useAuthenticationStoreV2 } from 'stores/auth'
import { useNotificationStore } from 'stores/notifications'
import { calculateTokenDelta } from 'utils'

let isAlreadyRefreshingIdentity = false
let failedQueryKeys: QueryKey[] = []

const refreshFailed = () => {
  useNotificationStore.getState().pushNotification({
    id: 'refresh-failed',
    title: 'No credentials',
    description:
      "We tried to refresh your credentials but failed. Please reload the page and if it doesn't sign you in automatically, please sign in again.",
    severity: 'urgent',
    forceOpen: true,
    static: true,
  })
}

export const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: async (error, query) => {
      if (
        error instanceof ContentResponseError ||
        error instanceof SmepResponseError ||
        error instanceof MediaResponseError ||
        error instanceof OrgResponseError ||
        error instanceof ShowcaseResponseError ||
        error instanceof ExpResponseError
      ) {
        if (error.response.status === 401 && query.queryKey.at(0) !== 'identity') {
          try {
            const { identityAccessToken, identityRefreshToken, setAuthenticationStoreV2PropertyValue } =
              useAuthenticationStoreV2.getState()

            failedQueryKeys.push(query.queryKey)

            if (!isAlreadyRefreshingIdentity) {
              isAlreadyRefreshingIdentity = true

              const refreshedIdentity = await IdentityAPI.refresh
                .refreshPost(
                  {
                    tokenRequest: {
                      accessToken: identityAccessToken,
                      refreshToken: identityRefreshToken,
                    },
                  },
                  { headers: { 'Content-Type': 'application/json' }, credentials: 'include' },
                )
                .catch(() => {
                  refreshFailed()
                  throw new Error('Identity refresh failed.')
                })

              if (!refreshedIdentity.accessToken || !refreshedIdentity.refreshToken) {
                refreshFailed()
                throw new Error('Some tokens were not present in response during identity refresh.')
              }

              setAuthenticationStoreV2PropertyValue(
                'accessDenialDelta',
                calculateTokenDelta(refreshedIdentity.accessToken),
              )

              setAuthenticationStoreV2PropertyValue('identityUserId', refreshedIdentity.userId as string)
              setAuthenticationStoreV2PropertyValue('identityAccessToken', refreshedIdentity.accessToken)
              setAuthenticationStoreV2PropertyValue('identityRefreshToken', refreshedIdentity.refreshToken ?? undefined)

              await Promise.allSettled(failedQueryKeys.map((queryKey) => queryClient.refetchQueries({ queryKey })))

              isAlreadyRefreshingIdentity = false
              failedQueryKeys = []
            }
          } catch (error) {
            isAlreadyRefreshingIdentity = false

            captureException(error)

            activeMsalVersion.logoutRedirect()
          }
        } else if (error.response.status >= 500) {
          useNotificationStore.getState().pushNotification({
            id: `bad-response-${error.response.status}`,
            title: 'Unexpected Server Problem',
            description:
              error.message ||
              "We tried to refresh your credentials but failed. Please reload the page and if it doesn't sign you in automatically, please sign in again.",
            severity: 'urgent',
            forceOpen: false,
            static: true,
          })
        }
      }
    },
  }),
  mutationCache: new MutationCache({
    onError: async (error) => {
      if (
        error instanceof ContentResponseError ||
        error instanceof SmepResponseError ||
        error instanceof MediaResponseError ||
        error instanceof OrgResponseError ||
        error instanceof ShowcaseResponseError ||
        error instanceof ExpResponseError
      ) {
        if (error.response.status >= 500) {
          useNotificationStore.getState().pushNotification({
            id: `bad-response-${error.response.status}`,
            title: 'Unexpected Server Problem',
            description: `An internal server error occurred, hindering the request due to potential misconfigurations or issues within the server's operation.${
              error.message ? `Original message: ${error.message}` : ''
            }`,
            severity: 'urgent',
            forceOpen: false,
            static: false,
          })
        }
      }
    },
  }),
  defaultOptions: {
    queries: {
      retry: 1,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  },
})
