import { memo, useMemo } from 'react'

import { Navigate, useLocation } from 'react-router-dom'

import { StaticRouteParameter } from 'config/routing'

const RedirectToProcessInvitationRaw = () => {
  const location = useLocation()

  const search = useMemo(() => {
    const existingParams = new URLSearchParams(location.search)
    existingParams.append('referrer', location.pathname.replace(`/${StaticRouteParameter.ACCEPT_INVITATION}`, ''))
    return existingParams
  }, [location.pathname, location.search])

  return <Navigate replace to={`/${StaticRouteParameter.PROCESS_INVITATION}?${search.toString()}`} />
}

export const RedirectToProcessInvitation = memo(RedirectToProcessInvitationRaw)
