import { PropsWithChildren, memo, useState } from 'react'

import { ExpandMore } from '@mui/icons-material'
import { Box, Collapse, IconButton, Paper, SxProps, Theme, Typography } from '@mui/material'

type AdvancedSettingsProps = {
  sx?: SxProps<Theme>
}

const AdvancedSettingsRaw = ({ children, sx = [] }: PropsWithChildren<AdvancedSettingsProps>) => {
  const [open, setOpen] = useState(false)

  return (
    <Paper sx={[{ m: -0.5, p: 1, bgcolor: 'background.levels.500' }, ...(Array.isArray(sx) ? sx : [sx])]}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography variant="heading-base">Advanced Settings</Typography>
        <IconButton size="small" onClick={() => setOpen(!open)}>
          <ExpandMore
            sx={[
              {
                transform: 'rotate(0deg)',
                transition: (theme) =>
                  theme.transitions.create('transform', {
                    duration: theme.transitions.duration.shortest,
                  }),
              },
              open && {
                transform: 'rotate(180deg)',
              },
            ]}
          />
        </IconButton>
      </Box>
      <Collapse in={open} unmountOnExit={false}>
        {children}
      </Collapse>
    </Paper>
  )
}

export const AdvancedSettings = memo(AdvancedSettingsRaw)
