import { Box, styled } from '@mui/material'

export const ActionStrip = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  columnGap: 16,
  width: '100%',
  padding: theme.spacing(0.5),
  marginBottom: theme.spacing(0.5),
  borderRadius: 4,
  backgroundColor: theme.palette.background.levels[500],
}))
