import { JwtPayload, jwtDecode } from 'jwt-decode'

export const calculateTokenDelta = (token: string) => {
  const header = jwtDecode<JwtPayload>(token, { header: true })
  const { exp } = jwtDecode<JwtPayload>(token)

  if (!header) {
    throw new Error('Invalid header in token')
  }
  if (!exp) {
    throw new Error('Invalid payload in token')
  }

  const now = Date.now() / 1000

  return Math.floor((exp - now) * 1000)
}
