import { TenantsTenantExternalAzureAdNativewavescloudLoginPostRequest } from '@nativewaves/platform-sdk-browser/identity'
import { queryOptions } from '@tanstack/react-query'

import { IdentityAPI } from 'services/api'

const baseIdentifier = 'identity'

export const externalQueryKeys = {
  base: () => [baseIdentifier, 'external'] as const,
  nativewavesCloudLoginWithAzureAD: (params: TenantsTenantExternalAzureAdNativewavescloudLoginPostRequest) =>
    queryOptions({
      // eslint-disable-next-line @tanstack/query/exhaustive-deps
      queryKey: [...externalQueryKeys.base(), 'nwcloud-login-ad'],
      queryFn: () => IdentityAPI.external.tenantsTenantExternalAzureAdNativewavescloudLoginPost(params),
    }),
}
