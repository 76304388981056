import { memo } from 'react'

import { Box, styled, Typography } from '@mui/material'

import ConstructionIllustration from 'assets/undraw/construction.svg?react'

const StyledConstructionIllustration = styled(ConstructionIllustration)({})

const UnderDevelopmentRaw = () => {
  return (
    <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <StyledConstructionIllustration sx={{ color: 'primary.main', width: 640, my: 4 }} />
      <Typography variant="heading-md">This feature is currently in development</Typography>
    </Box>
  )
}

export const UnderDevelopment = memo(UnderDevelopmentRaw)
