import { useEffect } from 'react'

import { captureException } from '@sentry/react'
import { useQuery } from '@tanstack/react-query'
import { useShallow } from 'zustand/react/shallow'

import { externalQueryKeys } from 'services/queryKeys/identity'
import { b2cPolicies } from 'setup/azureB2C'
import { selectAuthenticationStoreV2Props, useAuthenticationStoreV2 } from 'stores/auth'
import { calculateTokenDelta } from 'utils'

type UseIdentitySignInProps = {
  enabled?: boolean
  customIdToken?: string
}

export const useIdentitySignIn = ({ enabled = true, customIdToken }: UseIdentitySignInProps) => {
  const { providerIdToken, setAuthenticationStoreV2PropertyValue } = useAuthenticationStoreV2(
    useShallow(selectAuthenticationStoreV2Props('providerIdToken', 'setAuthenticationStoreV2PropertyValue')),
  )

  const identitySignInQuery = useQuery({
    ...externalQueryKeys.nativewavesCloudLoginWithAzureAD({
      tenant: 'NativeWaves',
      aDB2CLoginRequest: {
        policyId: b2cPolicies.names.signUpSignIn.toLowerCase(),
        idToken: customIdToken || providerIdToken,
      },
    }),
    enabled: (!!customIdToken?.length && !!enabled) || (!!providerIdToken?.length && !!enabled),
    staleTime: Infinity,
    retry: 1,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  })

  useEffect(() => {
    if (identitySignInQuery.isSuccess) {
      const derivedData = identitySignInQuery.data
      if (!derivedData.accessToken) {
        throw new Error('Access token not found')
      }

      if (!derivedData.refreshToken) {
        captureException(new Error('Refresh token not present in response'))
      }

      if (!derivedData.userId) {
        throw new Error('User ID not available')
      }

      setAuthenticationStoreV2PropertyValue('accessDenialDelta', calculateTokenDelta(derivedData.accessToken))

      setAuthenticationStoreV2PropertyValue('identityUserId', derivedData.userId)
      setAuthenticationStoreV2PropertyValue('identityAccessToken', derivedData.accessToken)
      setAuthenticationStoreV2PropertyValue('identityRefreshToken', derivedData.refreshToken ?? undefined)
    }
  }, [identitySignInQuery.data, identitySignInQuery.isSuccess, setAuthenticationStoreV2PropertyValue])

  return identitySignInQuery
}
