import { memo, PropsWithChildren } from 'react'

import { Box, styled, Typography } from '@mui/material'
import { SxProps } from '@mui/system'
import { useTranslation } from 'react-i18next'

import NotFoundIllustration from 'assets/undraw/not_found.svg?react'

const StyledNotFoundIllustration = styled(NotFoundIllustration)({})

type NotFoundProps = {
  sx?: SxProps
  isEmptyOverride?: boolean
}

const NotFoundRaw = ({ isEmptyOverride, sx, children }: PropsWithChildren<NotFoundProps>) => {
  const { t } = useTranslation()

  if (children && !isEmptyOverride) {
    return <>{children}</>
  }

  return (
    <Box sx={[{ flex: 1, width: '100%', display: 'grid', placeItems: 'center' }, ...(Array.isArray(sx) ? sx : [sx])]}>
      <StyledNotFoundIllustration sx={{ width: 320, color: 'primary.main' }} />
      <Typography sx={{ mb: 'auto' }} variant="text">
        {t('resourceNotFound')}
      </Typography>
    </Box>
  )
}

export const NotFound = memo(NotFoundRaw)
