import { memo, useState } from 'react'

import { useOutlet } from 'react-router-dom'

const FrozenOutletRaw = () => {
  const o = useOutlet()
  const [outlet] = useState(o)

  return <>{outlet}</>
}

export const FrozenOutlet = memo(FrozenOutletRaw)
