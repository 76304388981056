import {
  EventsGetRequest,
  EventsEventIdGetRequest,
  EventsEventIdProgramGetRequest,
  WorkspacesGetRequest,
  WorkspacesWorkspaceIdGetRequest,
  WorkspacesWorkspaceIdMembersGetRequest,
  WorkspacesWorkspaceIdMembersMemberIdGetRequest,
  WorkspacesWorkspaceIdGuestsGetRequest,
  WorkspacesWorkspaceIdGuestsGuestIdGetRequest,
  WorkspacesWorkspaceIdInvitationsGetRequest,
  WorkspacesWorkspaceIdInvitationsInvitationIdGetRequest,
} from '@nativewaves/platform-sdk-browser/smep'
import { infiniteQueryOptions, queryOptions } from '@tanstack/react-query'

import { SmepAPI } from 'services/api'

const baseIdentifier = 'smep'

export const eventQueryKeys = {
  base: () => [baseIdentifier, 'event'] as const,
  lists: () => [...eventQueryKeys.base(), 'list'] as const,
  list: (params: EventsGetRequest) =>
    infiniteQueryOptions({
      queryKey: [...eventQueryKeys.lists(), params] as const,
      queryFn: ({ pageParam, signal }) => SmepAPI.events.eventsGet({ ...params, next: pageParam }, { signal }),
      initialPageParam: params.next,
      getNextPageParam: (lastPage) => (lastPage.next.length ? lastPage.next : null),
    }),
  details: () => [...eventQueryKeys.base(), 'detail'] as const,
  detail: (params: EventsEventIdGetRequest) =>
    queryOptions({
      queryKey: [...eventQueryKeys.details(), params] as const,
      queryFn: () => SmepAPI.events.eventsEventIdGet(params),
    }),
  expManifest: (params: EventsEventIdProgramGetRequest) =>
    queryOptions({
      queryKey: [...eventQueryKeys.base(), 'exp-manifest', params] as const,
      queryFn: () => SmepAPI.events.eventsEventIdProgramGet(params),
    }),
}

export const workspaceQueryKeys = {
  base: () => [baseIdentifier, 'workspace'] as const,
  lists: () => [...workspaceQueryKeys.base(), 'list'] as const,
  list: (params: WorkspacesGetRequest) =>
    infiniteQueryOptions({
      queryKey: [...workspaceQueryKeys.lists(), params] as const,
      queryFn: ({ pageParam, signal }) => SmepAPI.workspaces.workspacesGet({ ...params, next: pageParam }, { signal }),
      initialPageParam: params.next,
      getNextPageParam: (lastPage) => (lastPage.next.length ? lastPage.next : null),
    }),
  details: () => [...workspaceQueryKeys.base(), 'detail'] as const,
  detail: (params: WorkspacesWorkspaceIdGetRequest) =>
    queryOptions({
      queryKey: [...workspaceQueryKeys.details(), params] as const,
      queryFn: () => SmepAPI.workspaces.workspacesWorkspaceIdGet(params),
    }),
  memberLists: () => [...workspaceQueryKeys.base(), 'member', 'list'] as const,
  memberList: (params: WorkspacesWorkspaceIdMembersGetRequest) =>
    infiniteQueryOptions({
      queryKey: [...workspaceQueryKeys.memberLists(), params] as const,
      queryFn: ({ pageParam, signal }) =>
        SmepAPI.workspaces.workspacesWorkspaceIdMembersGet({ ...params, next: pageParam }, { signal }),
      initialPageParam: params.next,
      getNextPageParam: (lastPage) => (lastPage.next.length ? lastPage.next : null),
    }),
  memberDetails: () => [...workspaceQueryKeys.base(), 'member', 'detail'] as const,
  memberDetail: (params: WorkspacesWorkspaceIdMembersMemberIdGetRequest) =>
    queryOptions({
      queryKey: [...workspaceQueryKeys.memberDetails(), params] as const,
      queryFn: () => SmepAPI.workspaces.workspacesWorkspaceIdMembersMemberIdGet(params),
    }),
  guestLists: () => [...workspaceQueryKeys.base(), 'guest', 'list'] as const,
  guestList: (params: WorkspacesWorkspaceIdGuestsGetRequest) =>
    infiniteQueryOptions({
      queryKey: [...workspaceQueryKeys.guestLists(), params] as const,
      queryFn: ({ pageParam, signal }) =>
        SmepAPI.workspaces.workspacesWorkspaceIdGuestsGet({ ...params, next: pageParam }, { signal }),
      initialPageParam: params.next,
      getNextPageParam: (lastPage) => (lastPage.next.length ? lastPage.next : null),
    }),
  guestDetails: () => [...workspaceQueryKeys.base(), 'guest', 'detail'] as const,
  guestDetail: (params: WorkspacesWorkspaceIdGuestsGuestIdGetRequest) =>
    queryOptions({
      queryKey: [...workspaceQueryKeys.guestDetails(), params] as const,
      queryFn: () => SmepAPI.workspaces.workspacesWorkspaceIdGuestsGuestIdGet(params),
    }),
  invitationLists: () => [...workspaceQueryKeys.base(), 'invitation', 'list'] as const,
  invitationList: (params: WorkspacesWorkspaceIdInvitationsGetRequest) =>
    infiniteQueryOptions({
      queryKey: [...workspaceQueryKeys.invitationLists(), params] as const,
      queryFn: ({ pageParam, signal }) =>
        SmepAPI.workspaces.workspacesWorkspaceIdInvitationsGet({ ...params, next: pageParam }, { signal }),
      initialPageParam: params.next,
      getNextPageParam: (lastPage) => (lastPage.next.length ? lastPage.next : null),
    }),
  invitationDetails: () => [...workspaceQueryKeys.base(), 'invitation', 'detail'] as const,
  invitationDetail: (params: WorkspacesWorkspaceIdInvitationsInvitationIdGetRequest) =>
    queryOptions({
      queryKey: [...workspaceQueryKeys.invitationDetails(), params] as const,
      queryFn: () => SmepAPI.workspaces.workspacesWorkspaceIdInvitationsInvitationIdGet(params),
    }),
}

/* 

const eventWorker = new Worker('/worker/event-worker.js')

export enum EventEntitiesKeys {
  ENTITIES = 'entities',
  VIDEOTRACKS = 'videoTracks',
  AUDIOTRACKS = 'audioTracks',
  GLOBAL_AUDIOS = 'globalAudios',
  MOMENTS = 'moments',
  SECTIONS = 'sections',
}
const eventEntities: Record<
  EventEntitiesKeys,
  {
    config: keyof SmepEventConfig
    status: keyof SmepEventStatus
    adjustment: keyof SmepEventConfig
  }
> = {
  entities: {
    config: 'entities',
    status: 'entities',
    adjustment: 'entityAdjustments',
  },
  videoTracks: {
    config: 'videoTracks',
    status: 'videoTracks',
    adjustment: 'videoTrackAdjustments',
  },
  audioTracks: {
    config: 'audioTracks',
    status: 'audioTracks',
    adjustment: 'audioTrackAdjustments',
  },
  globalAudios: {
    config: 'globalAudios',
    status: 'globalAudios',
    adjustment: 'globalAudioAdjustments',
  },
  moments: {
    config: 'moments',
    status: 'moments',
    adjustment: 'momentAdjustments',
  },
  sections: {
    config: 'sections',
    status: 'sections',
    adjustment: 'sectionAdjustments',
  },
}

export const useEventQueryWithWorkerTransformation = <T extends { adjustment: Adjustment<unknown> }>({
  eventId,
  entity,
  maps,
}: {
  eventId: string
  entity: EventEntitiesKeys
  maps: {
    ConfigMap: Map<string | null, SelfReferencingMapValueType<string[], string | null>>
    ConfigAdjustmentMap: Map<string | null, SelfReferencingMapValueType<string[], string | null>>
    StatusMap: Map<string | null, SelfReferencingMapValueType<string[], string | null>>
  }
}) => {
  const [renderLoading, setRenderLoading] = useState(true)
  const [workerError, setWorkerError] = useState(false)
  const [renderData, setRenderData] = useState<T[] | undefined>(undefined)
  const [invalidAdjustmentObjects, setInvalidAdjustmentObjects] = useState<T[]>([])

  const eventQueryResult = useEventQuery(
    { eventId },
    {
      refetchInterval: RefetchIntervals.FAST,
      onSuccess: useCallback(
        (data: SmepEvent) => {
          eventWorker.postMessage({
            maps,
            data: {
              configManualData: data.config?.[eventEntities[entity].config],
              configAdjustmentData: data.config?.[eventEntities[entity].adjustment],
              statusData: data.status?.[eventEntities[entity].status],
            },
          })
          eventWorker.onmessage = (event: MessageEvent<{ data?: Record<string, T>; error?: Error }>) => {
            if (event.data?.data) {
              setWorkerError(false)
              const mergedEventObject = event.data.data
              const invalidAdjustmentObjects = Object.values(mergedEventObject).filter(
                (object: T) => object?.adjustment?.invalid
              )
              setRenderData(
                Object.entries(mergedEventObject)
                  .flatMap(([id, object]) => ({ ...object, id }))
                  .filter((object) => !invalidAdjustmentObjects.includes(object))
              )
              setInvalidAdjustmentObjects(invalidAdjustmentObjects)
            }
            if (event.data.error) {
              setWorkerError(true)
            }
            setRenderLoading(false)
          }
        },
        [entity, maps]
      ),
    }
  )

  return {
    ...eventQueryResult,
    isInitialLoading: eventQueryResult.isInitialLoading || renderLoading,
    isWorkerError: workerError,
    data: renderData,
    invalidAdjustmentObjects,
  } as UseQueryResult<T[], unknown> & { invalidAdjustmentObjects: T[]; isWorkerError: boolean }
}

*/
