import { useRef, useEffect, useCallback } from 'react'

export const useHiddenActivator = (trigger: string, cb: () => void, condition = import.meta.env.DEV) => {
  const keyPressSequence = useRef<string[]>([])

  const listener = useCallback(
    (e: KeyboardEvent) => {
      if (!keyPressSequence.current?.length && e.key === trigger[0]) {
        keyPressSequence.current.push(e.key)
        if (keyPressSequence.current.length === trigger.length) {
          cb()
          keyPressSequence.current = []
        }
        return
      }
      const keyPressSequenceString = keyPressSequence.current.join('')
      if (trigger.startsWith(keyPressSequenceString) && trigger[keyPressSequence.current.length] === e.key) {
        if (`${keyPressSequenceString}${e.key}` === trigger) {
          cb()
          keyPressSequence.current = []
          return
        }
        keyPressSequence.current.push(e.key)
        return
      }
      keyPressSequence.current = []
      return
    },
    [trigger, cb]
  )

  useEffect(() => {
    if (condition) {
      window.addEventListener('keyup', listener)
      return () => window.removeEventListener('keyup', listener)
    }
  }, [condition, listener])
}
