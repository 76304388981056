import { ForwardedRef, forwardRef, memo, MouseEvent, PropsWithChildren, ReactNode } from 'react'

import { Close } from '@mui/icons-material'
import {
  IconButton,
  DialogTitle as MuiDialogTitle,
  DialogTitleProps as MuiDialogTitleProps,
  IconButtonProps,
  Box,
  Tooltip,
  SxProps,
} from '@mui/material'
import { useTranslation } from 'react-i18next'

export type DialogTitleProps = {
  sx?: SxProps
} & (
  | {
      onClose?: undefined
      closeIcon?: never
      closeIconSize?: never
      closeTooltipTitle?: never
    }
  | {
      onClose: (e: MouseEvent) => void
      closeIcon?: ReactNode
      closeIconSize?: IconButtonProps['size']
      closeTooltipTitle?: string
    }
)

const DialogTitleRaw = (
  {
    sx,
    onClose,
    closeIcon,
    children,
    closeIconSize,
    closeTooltipTitle,
    ...muiDialogTitleProps
  }: PropsWithChildren<Omit<MuiDialogTitleProps, 'sx'> & DialogTitleProps>,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  const { t } = useTranslation()
  return (
    <Box
      sx={[
        {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <MuiDialogTitle
        sx={{ maxWidth: 480, fontSize: '1.5em', fontWeight: 'bold' }}
        ref={ref}
        variant="heading-md"
        {...muiDialogTitleProps}
        noWrap
      >
        {children}
      </MuiDialogTitle>

      {onClose && (
        <Tooltip title={closeTooltipTitle ?? t('closePopup')} placement="left" arrow>
          <IconButton
            sx={{
              color: 'grey.500',
              my: 2,
              mx: 3,
            }}
            onClick={onClose}
            size={closeIconSize}
          >
            {closeIcon || <Close fontSize={closeIconSize} />}
          </IconButton>
        </Tooltip>
      )}
    </Box>
  )
}

export const DialogTitle = memo(forwardRef(DialogTitleRaw))
