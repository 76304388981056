import { memo, PropsWithChildren, ReactNode } from 'react'

import { Box, styled, Typography } from '@mui/material'
import { SxProps } from '@mui/system'
import { useTranslation } from 'react-i18next'

import BlankCanvasIllustration from 'assets/undraw/empty.svg?react'

const StyledBlankCanvasIllustration = styled(BlankCanvasIllustration)({})

type EmptyIndicatorProps = {
  icon?: ReactNode
  isEmptyOverride?: boolean
  subtitle?: ReactNode
  disableSubtitle?: boolean
  sx?: SxProps
}

const EmptyIndicatorRaw = ({
  children,
  icon,
  isEmptyOverride,
  subtitle,
  disableSubtitle,
  sx,
}: PropsWithChildren<EmptyIndicatorProps>) => {
  const { t } = useTranslation()

  if (children && !isEmptyOverride) {
    return <>{children}</>
  }

  return (
    <Box
      sx={[
        {
          flex: 1,
          p: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          rowGap: 1,
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {icon || <StyledBlankCanvasIllustration sx={{ width: 'auto', maxWidth: 320, color: 'primary.main' }} />}
      {!disableSubtitle && (
        <Typography sx={{ mt: 0 }} variant="heading-sm">
          {subtitle || t('noItemsCreatedYet')}
        </Typography>
      )}
    </Box>
  )
}

export const EmptyIndicator = memo(EmptyIndicatorRaw)
