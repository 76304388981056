export const extractState = (stateString: string) => {
  const derivedRedirectState = new URLSearchParams(stateString)

  const derivedInvitationId = derivedRedirectState?.get('invitationId') ?? undefined
  const derivedInvitationToken = derivedRedirectState?.get('invitationToken') ?? undefined
  const derivedReferrer = derivedRedirectState?.get('referrer')

  return {
    invitationId: derivedInvitationId,
    invitationToken: derivedInvitationToken,
    referrer: derivedReferrer,
  }
}
