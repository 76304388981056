import { memo } from 'react'

import { Cancel } from '@mui/icons-material'
import { Alert, AlertTitle, Box, Button, Paper, Typography, styled, useTheme } from '@mui/material'
import { AnimatePresence, motion } from 'framer-motion'
import { Link, useSearchParams } from 'react-router-dom'

import NWLogoFullWhite from 'assets/logos/nw-logo_full_size-adjusted.svg?react'
import NWLogoFullDark from 'assets/logos/nw-logo_full_white_size-adjusted.svg?react'
import {
  extractState,
  InvitationContext,
  ProcessAuthentication,
  CurrentUserCheck,
  UserDecision,
} from 'domains/ProcessInvitation'

const StyledNWLogoFullDark = styled(NWLogoFullDark)({
  height: 22,
})
const StyledNWLogoFullWhite = styled(NWLogoFullWhite)({
  height: 22,
})

const RootRaw = () => {
  const theme = useTheme()

  const [searchParams] = useSearchParams()

  const derivedStateVar = searchParams.get('state')

  const { invitationId, invitationToken, referrer } = derivedStateVar?.length
    ? extractState(decodeURI(derivedStateVar))
    : extractState(searchParams.toString())

  if (!invitationId || !invitationToken || !referrer) {
    return (
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'grid',
          gridTemplateRows: '1fr auto 1fr',
          gridTemplateColumns: '1fr minmax(auto, 480px) 1fr',
        }}
      >
        <Box sx={{ gridRow: 2, gridColumn: 2, display: 'grid', placeItems: 'center' }}>
          <AnimatePresence mode="wait">
            <Paper sx={{ p: 1.5, width: '100%', display: 'flex', flexDirection: 'column', rowGap: 2 }}>
              <Box sx={{ mb: 0.5, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography variant="heading-lg">Console</Typography>
                <Button
                  component="a"
                  href="https://nativewaves.com"
                  referrerPolicy="no-referrer"
                  rel="nooopener noreferrer"
                  target="_blank"
                >
                  {theme.palette.mode === 'light' ? <StyledNWLogoFullWhite /> : <StyledNWLogoFullDark />}
                </Button>
              </Box>
              <Alert severity="error">
                <AlertTitle>Access not allowed</AlertTitle>
                <Typography component="span" variant="inherit">
                  {`Oops! It looks like you don't have the necessary permissions to access this page. If you think this
                  is a mistake, please reach out to support for help.`}
                </Typography>
              </Alert>
              <Alert severity="info">
                <AlertTitle>Invitation</AlertTitle>

                <Typography variant="inherit" component="span">
                  If you arrived here via an invitation, please double-check the details to ensure everything is
                  correct.
                </Typography>
              </Alert>
              <Button sx={{ ml: 'auto' }} variant="contained" endIcon={<Cancel />} component={Link} replace to="/">
                Cancel
              </Button>
            </Paper>
          </AnimatePresence>
        </Box>
      </Box>
    )
  }

  return (
    <InvitationContext.Provider value={{ invitationId, invitationToken, referrer }}>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'grid',
          gridTemplateRows: '1fr auto 1fr',
          gridTemplateColumns: '1fr minmax(auto, 480px) 1fr',
        }}
      >
        <Box sx={{ gridRow: 2, gridColumn: 2, display: 'grid', placeItems: 'center' }}>
          <AnimatePresence mode="wait">
            <UserDecision>
              <ProcessAuthentication>
                <CurrentUserCheck />
              </ProcessAuthentication>
            </UserDecision>
          </AnimatePresence>
        </Box>
        <Box
          sx={{ gridRow: 3, gridColumn: 2, mx: 'auto', mt: 'auto' }}
          component={motion.div}
          initial={{
            opacity: 0,
          }}
          animate={{ opacity: 1 }}
        >
          <Paper sx={{ p: 1.5, mb: 4, display: 'flex', flexDirection: 'column', rowGap: 1.5 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography variant="heading-lg">Console</Typography>
              {theme.palette.mode === 'light' ? <StyledNWLogoFullWhite /> : <StyledNWLogoFullDark />}
            </Box>
            <Alert sx={{ mb: 1 }} severity="info">
              <AlertTitle>Invitation</AlertTitle>

              <Typography variant="inherit" component="span">
                You have been invited to join an Entity. The steps above will guide you through the invitation process.
              </Typography>
            </Alert>
            <Button sx={{ ml: 'auto' }} size="small" endIcon={<Cancel />} component={Link} replace to="/">
              Cancel
            </Button>
          </Paper>
        </Box>
      </Box>
    </InvitationContext.Provider>
  )
}

export const Root = memo(RootRaw)
