import React, { PropsWithChildren, memo, ReactNode } from 'react'

import { Close } from '@mui/icons-material'
import { Box, Drawer, DrawerProps, IconButton, LinearProgress, Theme, Typography } from '@mui/material'
import { SystemStyleObject } from '@mui/system'

type BigSideDrawerProps = {
  title?: ReactNode
  DrawerProps?: DrawerProps
  TitleBoxSx?: SystemStyleObject<Theme> | ((theme: Theme) => SystemStyleObject<Theme>)
  ContentBoxSx?: SystemStyleObject<Theme> | ((theme: Theme) => SystemStyleObject<Theme>)
  Actions?: ReactNode
  progress?: number
}

const BigSideDrawerRaw = ({
  title,
  DrawerProps,
  TitleBoxSx,
  ContentBoxSx,
  children,
  Actions,
  progress,
}: PropsWithChildren<BigSideDrawerProps>) => {
  return (
    <Drawer
      anchor="right"
      {...DrawerProps}
      PaperProps={{
        sx: {
          width: '85%',
          height: '100%',
          right: 0,
          marginLeft: 'auto',
          borderTopLeftRadius: 4,
          borderBottomLeftRadius: 4,
        },
      }}
    >
      <Box
        sx={[
          { height: 64, mx: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' },
          !!TitleBoxSx && TitleBoxSx,
        ]}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton
            sx={{ mr: 2 }}
            onClick={(e) => {
              if (DrawerProps?.onClose) {
                DrawerProps.onClose(e, 'escapeKeyDown')
              }
            }}
          >
            <Close />
          </IconButton>
          {!!title && (typeof title === 'string' ? <Typography variant="heading-md">{title}</Typography> : title)}
        </Box>
        {Actions}
      </Box>
      {progress !== undefined && <LinearProgress variant="determinate" value={progress} />}
      <Box
        sx={[
          { position: 'relative', height: '100%', width: '100%', overflow: 'hidden' },
          !!ContentBoxSx && ContentBoxSx,
        ]}
      >
        {children}
      </Box>
    </Drawer>
  )
}

export const BigSideDrawer = memo(BigSideDrawerRaw)
