import { ReactNode } from 'react'

import { v4 } from 'uuid'
import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'

const severities = {
  URGENT: 'urgent',
  ERROR: 'error',
  NORMAL: 'normal',
} as const
type Severity = (typeof severities)[keyof typeof severities]

type Notification = {
  id: string
  title: string
  description: string | ReactNode
  severity: Severity
  resolved: boolean
  amount: number
  resolveAction?: () => void
  static?: boolean
  forceOpen?: boolean
}

type NotificationDefaults = {
  notifications: Notification[]
}

type NotificationSetters = {
  pushNotification: (
    notification: Omit<Notification, 'id' | 'severity' | 'resolved' | 'amount'> &
      Partial<Pick<Notification, 'id' | 'severity'>>,
  ) => void
  resolveNotification: (id: string) => void
  clearResolvedNotifications: () => void
}

export const useNotificationStore = create(
  immer<NotificationDefaults & NotificationSetters>((set) => ({
    notifications: [],
    pushNotification: (notification) =>
      set((state) => {
        const idAlreadyExists = !!notification.id && state.notifications.find((item) => item.id === notification.id)

        if (idAlreadyExists) {
          idAlreadyExists.amount++
        } else {
          state.notifications.push({
            id: notification.id || v4(),
            severity: 'normal',
            resolved: false,
            amount: 1,
            ...notification,
          })
        }
      }),
    resolveNotification: (id) =>
      set((state) => {
        const notification =
          state.notifications[state.notifications.findIndex((notification) => notification.id === id)]

        notification.resolved = true
        if (notification.resolveAction) {
          notification.resolveAction()
        }
      }),
    clearResolvedNotifications: () =>
      set((state) => void (state.notifications = state.notifications.filter((notification) => !notification.resolved))),
  })),
)

// Selectors
export const selectNotifications = (state: NotificationDefaults) => state.notifications
export const selectUrgentNotifications = (state: NotificationDefaults) =>
  state.notifications.filter((notification) => notification.severity === 'urgent')
export const selectNormalNotifications = (state: NotificationDefaults) =>
  state.notifications.filter((notification) => notification.severity === 'normal')

export const selectNotificationSetters = (state: NotificationSetters) => ({
  pushNotification: state.pushNotification,
  resolveNotification: state.resolveNotification,
  clearResolvedNotifications: state.clearResolvedNotifications,
})
