import { PropsWithChildren, ReactNode, memo } from 'react'

import { DialogProps as MuiDialogProps, Dialog as MuiDialog } from '@mui/material'

import { DialogTitle, DialogTitleProps } from '@shared/components/MaterialUIEnhancements'
import { DialogContext } from '@shared/components/MaterialUIEnhancements/Dialog'
import { DialogPaper } from '@shared/layouts'

type DialogProps = Omit<MuiDialogProps, 'children' | 'onClose'> & {
  title?: ReactNode
  onClose: (event?: unknown, reason?: 'backdropClick' | 'escapeKeyDown') => void
  DialogTitleProps?: DialogTitleProps
}

const DialogRaw = ({ children, title, onClose, DialogTitleProps, ...DialogProps }: PropsWithChildren<DialogProps>) => {
  return (
    <MuiDialog onClose={onClose} PaperComponent={DialogPaper} {...DialogProps}>
      <DialogContext.Provider value={{ onClose }}>
        {title && (
          <DialogTitle onClose={onClose} {...DialogTitleProps}>
            {title}
          </DialogTitle>
        )}
        {children}
      </DialogContext.Provider>
    </MuiDialog>
  )
}

export const Dialog = memo(DialogRaw) as typeof DialogRaw
