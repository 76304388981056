import { memo, PropsWithChildren } from 'react'

import { SnackbarProvider as NotistackSnackbarProvider, SnackbarProviderProps } from 'notistack'

import { SnackPanel } from '.'

const SnackbarProviderRaw = ({ children, ...props }: PropsWithChildren<SnackbarProviderProps>) => {
  return (
    <NotistackSnackbarProvider
      maxSnack={3}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transitionDuration={400}
      TransitionProps={{
        direction: 'up',
      }}
      Components={{
        panel: SnackPanel,
      }}
      disableWindowBlurListener
      {...props}
    >
      {children}
    </NotistackSnackbarProvider>
  )
}

export const SnackbarProvider = memo(SnackbarProviderRaw)
