import React, { memo, useEffect, useState } from 'react'

import { Box, Fade, Modal } from '@mui/material'
import { ResponseError } from '@nativewaves/platform-sdk-browser/identity'
import { Outlet } from 'react-router-dom'

import { LoadingScreen } from 'layouts'
import { useRequestAccessAutomation, useRequestRefreshAutomation } from 'pages/AppInit/Auth'

const AuthenticationManagerRaw = () => {
  const { redirectIsLoading, providerQueryIsLoading, needsToSignInFirst, identityQuery } = useRequestAccessAutomation()
  useRequestRefreshAutomation()

  const [authenticationManagerOpen, setAuthenticationManagerOpen] = useState(true)

  useEffect(() => {
    if (!redirectIsLoading && !providerQueryIsLoading && !needsToSignInFirst && identityQuery.isSuccess) {
      setTimeout(() => {
        setAuthenticationManagerOpen(false)
      }, 1500)
    }
  }, [identityQuery.isSuccess, needsToSignInFirst, providerQueryIsLoading, redirectIsLoading])

  return (
    <>
      <Modal
        open={authenticationManagerOpen}
        onClose={undefined}
        closeAfterTransition
        onBackdropClick={undefined}
        disableAutoFocus
      >
        <Fade in={authenticationManagerOpen} timeout={300} mountOnEnter unmountOnExit>
          <Box
            sx={{
              height: '100%',
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              rowGap: 2,
            }}
          >
            <LoadingScreen
              needsToSignInFirst={needsToSignInFirst}
              identityError={identityQuery.error as ResponseError}
            />
          </Box>
        </Fade>
      </Modal>
      <Outlet />
    </>
  )
}

export const AuthenticationManager = memo(AuthenticationManagerRaw)
