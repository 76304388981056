import { TDynamicRouteParameterValues } from 'types/routing'

export const StaticRouteParameter = {
  JSON: 'JSON',
  AUDIOSTREAMS: 'audiostreams',
  VIDEOSTREAMS: 'videostreams',
  AVC_INSTANCE: 'avc-instances',
  AVOBJECTS: 'avobjects',
  AVSTREAMS: 'avstreams',
  DATAOBJECTS: 'dataobjects',
  DATASTREAMS: 'datastreams',
  ORIGINS: 'origins',
  ACCESS_KEYS: 'access-keys',
  PROGRAMS: 'programs',
  PROGRAM: 'program',
  OLD_PROGRAMS: 'old-programs',
  ORGANIZATIONS: 'organizations',
  AVC_INSTANCE_TASKS: 'tasks',
  SETTINGS: 'settings',
  FAQ: 'faq',
  SIGN_IN: 'sign-in',
  APPS: 'apps',
  EVENTS: 'events',
  EVENT_SESSIONS: 'sessions',
  CHANNELS: 'channels',
  CHANNEL_ENTRIES: 'channelEntries',
  CREATE_TEMPLATE: 'create-template',
  CREATE_TEMPLATE_CONFIGURATION: 'configuration',
  CREATE_TEMPLATE_CREATE_MAPPING: 'create-mapping',
  CREATE_TEMPLATE_VERIFY: 'verify',
  INSTANCES: 'instances',
  CLUSTERS: 'clusters',
  LOGS: 'logs',
  USER: 'user',
  CREDENTIALS: 'credentials',
  NEW: 'new',
  WORKSPACES: 'workspaces',
  PERMISSIONS: 'permissions',
  SERIES: 'series',
  DASHBOARD: 'dashboard',
  AVTASKS: 'avtasks',
  CONTENT: 'content',
  AV: 'av',
  DATA: 'data',
  STREAMS: 'streams',
  INTEGRATIONS: 'integrations',
  SHOWCASES: 'showcases',
  HARDWARE: 'hardware',
  OAUTH: 'oauth',
  AZURE: 'azure',
  SETUP: 'setup',
  ORG: 'org',
  IMAGES: 'images',
  VIDEOS: 'videos',
  AUDIOS: 'audios',
  AVIO: 'avio',
  STATUS: 'status',
  CONFIG: 'config',
  ALERTS: 'alerts',
  GENERIC_CONTENT_STREAMS: 'generic-content-streams',
  OBJECTS: 'objects',
  ENVIRONMENTS: 'environments',
  MOMENTS: 'moments',
  SECTIONS: 'sections',
  LINE_CONTEXTS: 'line-contexts',
  VIDEO_TRACKS: 'video-tracks',
  AUDIO_TRACKS: 'audio-tracks',
  ENTITIES: 'entities',
  CLIPS: 'clips',
  GLOBAL_AUDIOS: 'global-audios',
  COLLECTIONS: 'collections',
  MEDIA_LIBRARIES: 'media-libraries',
  MEDIA_CHANNELS: 'media-channels',
  MEDIA_CHANNEL_ENTRIES: 'entries',
  MEDIAS: 'medias',
  UPLOADS: 'uploads',
  AVC_TASKS: 'avc-tasks',
  MY: 'my',
  ACCOUNT: 'account',
  META: 'meta',
  RAW: 'raw',
  EXP_APPS: 'exp-apps',
  TEAMS: 'teams',
  MANAGE_ACCESS: 'manage-access',
  MANAGE_TEAMS: 'manage-teams',
  MANAGED_STORES: 'managed-stores',
  EXTERNAL_STORES: 'external-stores',
  INVITATIONS: 'invitations',
  ACCEPT_INVITATION: 'acceptInvitation',
  PROCESS_INVITATION: 'process-invitation',
} as const

export const DynamicRouteParameter = {
  AVC_INSTANCE_ID: 'avcInstanceId',
  AVC_INSTANCE_TASK_ID: 'avcInstanceTaskId',
  AVOBJECT_ID: 'avobjectId',
  AVSTREAM_ID: 'avstreamId',
  DATAOBJECT_ID: 'dataobjectId',
  DATASTREAM_ID: 'datastreamId',
  ORIGIN_ID: 'originId',
  ACCESS_KEY_ID: 'accessKeyId',
  PROGRAM_ID: 'programId',
  APP_ID: 'appId',
  EVENT_ID: 'eventId',
  EVENT_SESSION_ID: 'sessionId',
  CHANNEL_ID: 'channelId',
  CHANNEL_ENTRY_ID: 'channelEntryId',
  INSTANCE_ID: 'instanceId',
  WORKSPACE_ID: 'workspaceId',
  ENVIRONMENT_ID: 'environmentId',
  AUDIOSTREAM_ID: 'audiostreamId',
  AUDIOOBJECT_ID: 'audioobjectId',
  VIDEOSTREAM_ID: 'videostreamId',
  VIDEOOBJECT_ID: 'videoobjectId',
  STREAM_ID: 'streamId',
  MOMENT_ID: 'momentId',
  SECTION_ID: 'sectionId',
  VIDEO_TRACK_ID: 'videoTrackId',
  AUDIO_TRACK_ID: 'audioTrackId',
  ENTITY_ID: 'entityId',
  CLIP_ID: 'clipId',
  GLOBAL_AUDIO_ID: 'globalAudioId',
  COLLECTION_ID: 'collectionId',
  SHOWCASE_ID: 'showcaseId',
  MEDIA_LIBRARY_ID: 'mediaLibraryId',
  MEDIA_CHANNEL_ID: 'mediaChannelId',
  MEDIA_CHANNEL_ENTRY_ID: 'mediaChannelEntryId',
  MEDIA_ID: 'mediaId',
  OBJECT_ID: 'objectId',
  AVC_TASK_ID: 'avcTaskId',
  ORGANIZATION_ID: 'organizationId',
  EXP_APP_ID: 'expAppId',
  TEAM_ID: 'teamId',
  STORE_ID: 'storeId',
  INVITATION_ID: 'invitationId',
} as const

export const DynamicRouteParameterWPrefix = Object.entries(DynamicRouteParameter).reduce(
  (acc, item) => ({ ...acc, [item[0]]: `:${item[1]}` }) as const,
  {} as Readonly<Record<keyof typeof DynamicRouteParameter, `:${TDynamicRouteParameterValues}`>>,
)
