import React, { PropsWithChildren, FormEvent, memo, ReactNode, useCallback, ReactElement, useState } from 'react'

import { Save } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import {
  Dialog,
  Box,
  DialogContent,
  DialogContentText,
  DialogActions,
  ButtonProps,
  styled,
  Alert,
  Button,
  Fade,
  DialogProps,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Link, To } from 'react-router-dom'

import { DialogTitle } from '@shared/components/MaterialUIEnhancements'
import CreateIllustration from 'assets/undraw/create.svg?react'

const StyledCreateIllustration = styled(CreateIllustration)({})

type NewDialogSkeletonProps = Pick<DialogProps, 'open' | 'onClose'> & {
  onSubmit: (e?: FormEvent<HTMLFormElement>) => void
  isInitialLoading?: boolean
  isSuccess?: boolean
  isError?: boolean
  To?: To
  Illustration?: true | ReactElement
  SaveButton?: ReactNode
  SaveButtonProps?: ButtonProps
  Translations: {
    entitySingle: string
  }
  children?: ReactNode
  SubmitProgressZone?: ReactNode
}

const NewDialogSkeletonRaw = ({
  onSubmit,
  open,
  onClose,
  isInitialLoading,
  isSuccess,
  isError,
  To,
  Illustration = true,
  SaveButton,
  SaveButtonProps,
  Translations,
  children,
  SubmitProgressZone,
}: PropsWithChildren<NewDialogSkeletonProps>) => {
  const { t } = useTranslation()

  const [retryCounter, setRetryCounter] = useState(0)

  const handleClose = useCallback(() => {
    if (onClose) {
      onClose({}, 'escapeKeyDown')
      setTimeout(() => {
        setRetryCounter(0)
      }, 300)
    }
  }, [onClose])

  return (
    <Dialog open={open} onClose={handleClose} fullWidth TransitionProps={{ unmountOnExit: true }}>
      <Box component="form" onSubmit={onSubmit}>
        <DialogTitle onClose={handleClose} closeIconSize="medium">
          {t('actions.entityCreateHeader', { entity: Translations.entitySingle })}
        </DialogTitle>
        <DialogContent sx={{ pt: 1, pb: 1, display: 'flex', flexDirection: 'column' }}>
          {Illustration ? (
            <StyledCreateIllustration sx={{ height: 150, mx: 'auto', mb: 2, color: 'primary.main' }} />
          ) : (
            Illustration || null
          )}
          <DialogContentText sx={{ pb: 1 }}>
            {t('actions.entityCreateDescription', { entity: Translations.entitySingle })}
          </DialogContentText>
          {children}
        </DialogContent>
        <DialogActions
          sx={{ pb: 1, px: 3, display: 'flex', height: 80, alignItems: 'center', justifyContent: 'space-between' }}
        >
          {SubmitProgressZone || (
            <>
              <Fade in={isSuccess} mountOnEnter unmountOnExit>
                <Alert
                  sx={{ mr: 'auto', '& .MuiAlert-action': { p: 0, mr: 0, ml: 2, alignItems: 'center' } }}
                  severity="success"
                  variant="outlined"
                  action={
                    To && (
                      <Button
                        sx={{ ml: 2, whiteSpace: 'nowrap', width: 'auto' }}
                        size="small"
                        variant="outlined"
                        component={Link}
                        to={To}
                      >
                        {t('open')}
                      </Button>
                    )
                  }
                >
                  {t('success.entityCreate', { entity: Translations.entitySingle })}
                </Alert>
              </Fade>
              <Fade in={isError} mountOnEnter unmountOnExit>
                <Alert
                  sx={{ mr: 'auto', '& .MuiAlert-action': { p: 0, mr: 0, ml: 2, alignItems: 'center' } }}
                  severity="error"
                  variant="outlined"
                  action={
                    retryCounter < 3 && (
                      <Button
                        sx={{ ml: 2, whiteSpace: 'nowrap', width: 'auto' }}
                        type="submit"
                        size="small"
                        variant="outlined"
                        onClick={() => setRetryCounter(retryCounter + 1)}
                      >
                        {t('retry')}
                      </Button>
                    )
                  }
                >
                  {retryCounter < 3 ? t('error.entityCreate.retry') : t('error.entityCreate.absolute')}
                </Alert>
              </Fade>
            </>
          )}
          {SaveButton || (
            <LoadingButton
              sx={{ ml: 'auto' }}
              startIcon={<Save />}
              loading={isInitialLoading}
              loadingPosition="start"
              type="submit"
              variant="text"
              {...SaveButtonProps}
            >
              {t('save')}
            </LoadingButton>
          )}
        </DialogActions>
      </Box>
    </Dialog>
  )
}

export const NewDialogSkeleton = memo(NewDialogSkeletonRaw)
