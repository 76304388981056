import { memo } from 'react'

import { Check, Warning } from '@mui/icons-material'
import { InputAdornment, InputAdornmentProps, Tooltip } from '@mui/material'
import { ControllerFieldState } from 'react-hook-form'

type InputErrorEndAdornmentProps = {
  fieldState: ControllerFieldState
  isSubmitted: boolean
  AdornmentProps?: InputAdornmentProps
}

const InputErrorEndAdornmentRaw = ({ fieldState, isSubmitted, AdornmentProps }: InputErrorEndAdornmentProps) => {
  if (isSubmitted && !fieldState.invalid) {
    return (
      <InputAdornment sx={{ cursor: 'auto' }} position="end" {...AdornmentProps}>
        <Check color={fieldState.isDirty ? 'success' : 'disabled'} fontSize="inherit" />
      </InputAdornment>
    )
  }

  if (fieldState.error) {
    return (
      <Tooltip title={fieldState.error?.message || 'Error unknown'} placement="top" arrow>
        <InputAdornment sx={{ cursor: 'help' }} position="end" {...AdornmentProps}>
          <Warning color="error" fontSize="inherit" />
        </InputAdornment>
      </Tooltip>
    )
  }

  return null
}

export const InputErrorEndAdornment = memo(InputErrorEndAdornmentRaw)
