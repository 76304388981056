import { memo, PropsWithChildren } from 'react'

import { Box, Skeleton, SxProps } from '@mui/material'

type LoadingIndicatorProps = {
  isLoadingOverride?: boolean
  sx?: SxProps
}

const LoadingIndicatorRaw = ({ isLoadingOverride, sx, children }: PropsWithChildren<LoadingIndicatorProps>) => {
  if (children && !isLoadingOverride) {
    return <>{children}</>
  }

  return (
    <Box
      sx={[{ height: '100%', display: 'flex', flexDirection: 'column', flex: 1 }, ...(Array.isArray(sx) ? sx : [sx])]}
    >
      <Box sx={{ mb: 2 }}>
        <Skeleton sx={{ height: 60 }} variant="rectangular" />
      </Box>
      <Skeleton sx={{ flex: 1 }} variant="rectangular" />
      <Box sx={{ my: 2 }}>
        <Skeleton variant="rectangular" />
      </Box>
      <Skeleton sx={{ flex: 1 }} variant="rectangular" />
      <Box sx={{ my: 2 }}>
        <Skeleton variant="rectangular" />
      </Box>
      <Skeleton sx={{ flex: 1 }} variant="rectangular" />
      <Box sx={{ mt: 2 }}>
        <Skeleton sx={{ height: 60 }} variant="rectangular" />
      </Box>
    </Box>
  )
}

export const LoadingIndicator = memo(LoadingIndicatorRaw)
