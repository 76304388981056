import { useIdentitySignIn } from 'pages/AppInit/Auth'
import { useADB2CSignIn } from 'pages/AppInit/Auth/useADB2CSignIn'

export const useRequestAccessAutomation = () => {
  const { providerQuery, redirectQuery } = useADB2CSignIn({})

  const identityQuery = useIdentitySignIn({
    enabled: !!(redirectQuery.isSuccess && redirectQuery.data !== null) || providerQuery.isSuccess,
    customIdToken: redirectQuery.data?.idToken ?? providerQuery.data?.idToken,
  })

  return {
    redirectIsLoading: redirectQuery.isLoading,
    providerQueryIsLoading: providerQuery.isLoading,
    needsToSignInFirst: providerQuery.data === null,
    identityQuery,
  }
}
