import { PropsWithChildren, memo } from 'react'

import { Box } from '@mui/material'
import { Outlet } from 'react-router-dom'

const DashboardSkeletonRaw = ({ children }: PropsWithChildren) => {
  return (
    <Box
      sx={{
        maxWidth: (theme) => theme.breakpoints.values.xl,
        mx: 'auto',
        width: '100%',
      }}
    >
      {children ?? <Outlet />}
    </Box>
  )
}

export const DashboardSkeleton = memo(DashboardSkeletonRaw)
