import { Children, PropsWithChildren, memo } from 'react'

import { Box, ButtonBase, ButtonBaseProps, SvgIcon, SxProps, styled } from '@mui/material'
import { DotSingle } from 'emotion-icons/entypo'

export const SnackPanelActionButton = styled((props: ButtonBaseProps) => <ButtonBase {...props} disableRipple />)(
  ({ theme }) => ({
    fontFamily: 'inherit',
    ...theme.typography['heading-sm'],

    padding: theme.spacing(1, 0),
    background: 'inherit',
    color: theme.palette.primary.main,
    textDecoration: 'inherit',
    whiteSpace: 'nowrap',

    '&:hover': {
      textDecoration: 'underline',
    },
  }),
) as typeof ButtonBase

const SnackPanelActionsRaw = ({
  children,
  sx,
}: PropsWithChildren<{
  sx?: SxProps
}>) => {
  return (
    <Box sx={[{ gridColumn: 2, display: 'flex', alignItems: 'center' }, ...(Array.isArray(sx) ? sx : [sx])]}>
      {Array.isArray(Children.toArray(children))
        ? Children.map(children, (child, idx) => (
            <>
              {idx > 0 && (
                <SvgIcon sx={{ alignSelf: 'center' }} fontSize="inherit">
                  <DotSingle />
                </SvgIcon>
              )}
              {child}
            </>
          ))
        : children}
    </Box>
  )
}

export const SnackPanelActions = memo(SnackPanelActionsRaw)
