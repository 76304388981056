import { useEffect, useCallback } from 'react'

import { Typography } from '@mui/material'
import { captureMessage } from '@sentry/react'
import { useTranslation } from 'react-i18next'
import { useRegisterSW } from 'virtual:pwa-register/react'
import { shallow } from 'zustand/shallow'

import { selectNotificationSetters, useNotificationStore } from 'stores/notifications'

export const useServiceWorker = () => {
  const { t } = useTranslation()

  const { pushNotification } = useNotificationStore(selectNotificationSetters, shallow)

  const {
    needRefresh: [needRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    onRegisterError(error) {
      captureMessage(`ServiceWorker registration failed: ${error}`, { level: 'error' })
    },
  })

  const resolveAction = useCallback(() => updateServiceWorker(false), [updateServiceWorker])

  useEffect(() => {
    if (needRefresh) {
      pushNotification({
        title: t('serviceWorker.updateQueued'),
        description: (
          <>
            {t('serviceWorker.updatePushedToEnv', { environment: import.meta.env.VITE_NW_ENVIRONMENT_INSTANCE })}{' '}
            <Typography variant="text-sm" component="strong">
              {t('serviceWorker.plsReload')}{' '}
            </Typography>
            {t('serviceWorker.disclaimer')}
          </>
        ),
        severity: 'urgent',
        forceOpen: true,
        resolveAction,
      })
    }
  }, [needRefresh, pushNotification, resolveAction, t])
}
