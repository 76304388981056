import { captureConsoleIntegration, httpClientIntegration } from '@sentry/integrations'
import * as Sentry from '@sentry/react'

if (import.meta.env.PROD) {
  Sentry.init({
    dsn: import.meta.env.VITE_NW_SENTRY_DSN,
    environment: import.meta.env.VITE_NW_ENVIRONMENT_INSTANCE,
    release: import.meta.env.VITE_NW_SENTRY_RELEASE,
    tracesSampleRate: 1.0,
    integrations: [
      // eslint-disable-next-line import/namespace
      Sentry.browserTracingIntegration(),
      captureConsoleIntegration({
        levels: ['error', 'warn'],
      }),
      httpClientIntegration({
        failedRequestStatusCodes: [
          [400, 499],
          [500, 599],
        ],
      }),
    ],
    tracePropagationTargets: [/https:\/\/(\w|\d)+\.api\.((dev|test)\.)*nativewaves\.com/],
  })
}
