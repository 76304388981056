import { memo, ReactNode, useMemo } from 'react'

import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemProps,
  ListItemText,
  ListItemTextProps,
  Skeleton,
  Typography,
  TypographyProps,
} from '@mui/material'
import { Link, matchPath, useLocation, useMatch, useResolvedPath } from 'react-router-dom'

type ListItemNavLinkProps = ListItemProps<typeof Link> & {
  label?: string
  loading?: boolean
  startIcon?: ReactNode
  TypographyProps?: TypographyProps<'h4'>
  ListItemTextProps?: ListItemTextProps
}

type ListItemLinkVariants =
  | {
      matchWildCard: true
      matchExactly?: never
    }
  | {
      matchWildCard?: never
      matchExactly: string[]
    }
  | {
      matchWildCard?: never
      matchExactly?: never
    }

type ListItemIconVariants =
  | {
      endIcon: ReactNode
      endAction?: never
    }
  | {
      endIcon?: never
      endAction: ReactNode
    }
  | {
      endIcon?: never
      endAction?: never
    }

const ListItemNavLinkRaw = ({
  label,
  loading,
  startIcon,
  endIcon,
  endAction,
  matchWildCard,
  matchExactly,
  TypographyProps,
  ListItemTextProps,
  ...listItemLinkProps
}: ListItemNavLinkProps & ListItemLinkVariants & ListItemIconVariants) => {
  const location = useLocation()
  const { pathname: resolvedToPath } = useResolvedPath(`${listItemLinkProps.to}${matchWildCard ? '/*' : ''}`)
  const match = useMatch({ path: resolvedToPath })
  const exactMatches = useMemo(
    () =>
      !matchWildCard &&
      !!matchExactly &&
      matchExactly
        .map((match) => matchPath(`${listItemLinkProps.to}/${match}`, location.pathname))
        .some((item) => !!item),
    [listItemLinkProps.to, location.pathname, matchExactly, matchWildCard],
  )

  return (
    <ListItem
      sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        '& .MuiListItemText-root': {
          mr: 4,
        },
      }}
      secondaryAction={endAction}
      disablePadding
    >
      <ListItemButton
        sx={[{ display: 'flex', alignItems: 'center' }, !!endAction && { flex: 1 }]}
        component={Link}
        selected={exactMatches || !!match}
        {...listItemLinkProps}
      >
        {startIcon && (
          <ListItemIcon
            sx={{
              color: (theme) => (exactMatches || !!match ? theme.palette.info.main : 'inherit'),
            }}
          >
            {startIcon}
          </ListItemIcon>
        )}
        <ListItemText
          disableTypography
          primary={
            <Typography variant="text" {...TypographyProps} noWrap>
              {loading ? <Skeleton variant="text" /> : label}
            </Typography>
          }
          {...ListItemTextProps}
        />
        {endIcon && <ListItemIcon sx={{ ml: 'auto', minWidth: 'unset' }}>{endIcon}</ListItemIcon>}
      </ListItemButton>
    </ListItem>
  )
}

export const ListItemNavLink = memo(ListItemNavLinkRaw)
