import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'

type PersonalInformation = {
  displayName?: string
  firstName?: string
  lastName?: string
  emailAdresses: string[]
  phone?: string
}

type ProviderAuthenticationInformation = {
  providerIdToken?: string
  providerAccessToken?: string
  providerRefreshToken?: string
  socialSignInMethod?: boolean
}

type IdentityAuthenticationInformation = {
  identityUserId?: string
  accessDenialDelta?: number
  identityIdToken?: string
  // START - For debugging purposes: (disabled on production-builds)
  identityAccessToken?: string
  identityRefreshToken?: string
  // END
}

type AuthenticationStateV2 = PersonalInformation & ProviderAuthenticationInformation & IdentityAuthenticationInformation

type SetterV2 = {
  setAuthenticationStoreV2PropertyValue: <K extends keyof AuthenticationStateV2>(
    key: K,
    value: AuthenticationStateV2[K] | undefined,
  ) => void
}

type StateAndSetterCombined = AuthenticationStateV2 & SetterV2

export const useAuthenticationStoreV2 = create(
  immer<StateAndSetterCombined>((set) => ({
    emailAdresses: [],
    setAuthenticationStoreV2PropertyValue: (key, value) =>
      set((state: AuthenticationStateV2) => {
        return {
          ...state,
          [key]: value,
        }
      }),
  })),
)

export const selectAuthenticationStoreV2Props =
  <K extends (keyof StateAndSetterCombined)[]>(...keys: K) =>
  (state: StateAndSetterCombined) =>
    keys.reduce(
      (acc, key) => ({
        ...acc,
        [key]: state[key],
      }),
      {} as { [key in K[number]]: StateAndSetterCombined[key] },
    )
