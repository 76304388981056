// Copied from https://github.com/mantinedev/mantine/blob/master/src/mantine-hooks/src/use-hotkeys/use-hotkeys.ts

import { useEffect } from 'react'

import { getHotkeyMatcher } from '@shared/utils/common'

export type HotkeyItem = [string, (event: KeyboardEvent) => void]

function shouldFireEvent(event: KeyboardEvent) {
  if (event.target instanceof HTMLElement) {
    return !event.target.isContentEditable && !['INPUT', 'TEXTAREA', 'SELECT'].includes(event.target.tagName)
  }
  return true
}

export function useHotkeys(hotkeys: HotkeyItem[]) {
  useEffect(() => {
    const keydownListener = (event: KeyboardEvent) => {
      hotkeys.forEach(([hotkey, handler]) => {
        if (getHotkeyMatcher(hotkey)(event) && shouldFireEvent(event)) {
          event.preventDefault()
          handler(event)
        }
      })
    }

    document.documentElement.addEventListener('keydown', keydownListener)
    return () => document.documentElement.removeEventListener('keydown', keydownListener)
  }, [hotkeys])
}
