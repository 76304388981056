import { ForwardedRef, ReactNode, forwardRef, memo, useCallback, useEffect, useRef, useState } from 'react'

import { Cancel, CheckCircle } from '@mui/icons-material'
import { Box, ButtonProps, IconButton, Paper, SvgIcon, SvgIconProps, Typography } from '@mui/material'
import { ErrorAlt } from 'emotion-icons/boxicons-regular'
import { CustomContentProps, SnackbarContent, useSnackbar } from 'notistack'

import { SnackPanelProgress } from '@shared/components/Snackbar'

const iconDefaultProps = {
  fontSize: 'small',
} satisfies SvgIconProps

const buttonDefaultProps = {
  sx: { cursor: 'pointer' },
  variant: 'text',
  size: 'small',
} satisfies ButtonProps

const iconVariantMap = {
  success: (
    <SvgIcon {...iconDefaultProps} color="success">
      <CheckCircle />
    </SvgIcon>
  ),
  caution: (
    <SvgIcon {...iconDefaultProps} color="error">
      <ErrorAlt />
    </SvgIcon>
  ),
} as const

export type SnackPanelProps = {
  icon: keyof typeof iconVariantMap | ((defaultProps: typeof iconDefaultProps) => ReactNode)
  description?: ReactNode
  Actions?: (props: {
    buttonDefaultProps: typeof buttonDefaultProps
    onClose: (preventExitHookExecution?: boolean) => void
  }) => ReactNode
  AdvancedSlot?: ReactNode
  onExitHook?: () => void
}

const SnackPanelRaw = (
  {
    icon,
    description,
    Actions,
    AdvancedSlot,
    message,
    autoHideDuration = 10000,
    persist,
    id: snackbarId,
    onExitHook,
  }: SnackPanelProps & CustomContentProps,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  const { closeSnackbar } = useSnackbar()

  const [isHovering, setIsHovering] = useState(false)

  const timeoutRef = useRef<NodeJS.Timeout | null>()

  const handleClose = useCallback(
    (preventExitHookExecution?: boolean) => {
      if (!preventExitHookExecution) {
        onExitHook?.()
      }

      closeSnackbar(snackbarId)
    },
    [closeSnackbar, onExitHook, snackbarId],
  )

  useEffect(() => {
    if (autoHideDuration) {
      if (isHovering) {
        clearTimeout(timeoutRef.current!)
        timeoutRef.current = null
      }
    }
  }, [autoHideDuration, isHovering])

  useEffect(() => {
    if (autoHideDuration) {
      if (!isHovering) {
        const isResumed = timeoutRef.current === null

        timeoutRef.current = setTimeout(
          () => {
            onExitHook?.()
          },
          isResumed ? Math.ceil(autoHideDuration / 2.5) : autoHideDuration,
        )
      }

      return () => {
        clearTimeout(timeoutRef.current!)
      }
    }
  }, [autoHideDuration, isHovering, onExitHook])

  return (
    <SnackbarContent
      ref={ref}
      role="alert"
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <Paper
        sx={{
          position: 'relative',
          minWidth: 280,
          width: '40vw',
          maxWidth: 480,
          maxHeight: '40vh',
          p: 2,
          display: 'grid',
          gridTemplateColumns: 'minmax(auto, 36px) 1fr auto',
          gridAutoRows: 'auto',
          columnGap: 1,
          rowGap: 1,
          alignItems: 'center',
          overflow: 'hidden',
        }}
        variant="outlined"
      >
        {icon && (
          <Box sx={{ gridRow: 1, gridColumn: 1, display: 'flex', alignItems: 'center' }}>
            {typeof icon === 'string' ? iconVariantMap[icon] : icon(iconDefaultProps)}
          </Box>
        )}
        <Typography sx={{ gridRow: 1, gridColumn: 2 }} variant="heading-base">
          {message}
        </Typography>
        <IconButton sx={{ gridRow: 1 }} size="small" onClick={() => handleClose()}>
          <Cancel fontSize="inherit" />
        </IconButton>
        {typeof description === 'string' ? (
          <Typography
            sx={{ mt: -1, gridRow: 2, gridColumn: 2, whiteSpace: 'break-spaces', overflow: 'auto' }}
            variant="text"
          >
            {description}
          </Typography>
        ) : (
          <Box sx={{ mt: -1, gridRow: 2, gridColumn: 2, whiteSpace: 'break-spaces', overflow: 'auto' }}>
            {description}
          </Box>
        )}
        {Actions?.({ buttonDefaultProps, onClose: handleClose })}
        {AdvancedSlot}

        {!persist && !!autoHideDuration && (
          <SnackPanelProgress autoHideDuration={autoHideDuration} pauseProgress={isHovering} />
        )}
      </Paper>
    </SnackbarContent>
  )
}

export const SnackPanel = memo(forwardRef(SnackPanelRaw))
