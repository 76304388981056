import { Configuration, EventType, LogLevel, PublicClientApplication } from '@azure/msal-browser'
import { captureMessage } from '@sentry/react'

export const claims = ['https://cloud.nativewaves.com/ab63b877-d896-4c00-8931-fadfe7cd28bd/user_impersonation']

export const b2cPolicies = {
  names: {
    signUpSignIn: 'B2C_1A_SIGNUP_SIGNIN',
    forgotPassword: 'B2C_1A_PASSWORDRESET',
    editProfile: 'B2C_1A_PROFILEEDIT',
  },
  authorities: {
    signUpSignIn: {
      authority: 'https://account.nativewaves.com/2fe9f80c-0665-41e2-a745-e8ec0555fcca/b2c_1a_signup_signin',
    },
    forgotPassword: {
      authority: 'https://account.nativewaves.com/2fe9f80c-0665-41e2-a745-e8ec0555fcca/b2c_1a_passwordreset',
    },
    editProfile: {
      authority: 'https://account.nativewaves.com/2fe9f80c-0665-41e2-a745-e8ec0555fcca/b2c_1a_profileedit',
    },
  },
  authorityDomain: 'account.nativewaves.com',
}

const msalConfigV2: Configuration = {
  auth: {
    clientId: import.meta.env.VITE_NW_AZURE_AD_B2C_CLIENT_ID, // This is the ONLY mandatory field that you need to supply.
    authority: b2cPolicies.authorities.signUpSignIn.authority, // Choose SUSI as your default authority.
    knownAuthorities: [b2cPolicies.authorityDomain], // Mark your B2C tenant's domain as trusted.
    // redirectUri: window.location.origin, // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
    postLogoutRedirectUri: '/', // Indicates the page to navigate after logout.
    navigateToLoginRequestUrl: true, // If "true", will navigate back to the original request location before processing the auth code response.
  },
  cache: {
    cacheLocation: 'localStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (import.meta.env.PROD) {
          if (containsPii) {
            return
          }
          switch (level) {
            case LogLevel.Error:
              captureMessage(message, 'error')
              return
            case LogLevel.Warning:
            default:
              return
          }
        }
      },
    },
  },
}

export const activeMsalVersion = new PublicClientApplication(msalConfigV2)

if (!activeMsalVersion.getActiveAccount() && activeMsalVersion.getAllAccounts().length > 0) {
  activeMsalVersion.setActiveAccount(activeMsalVersion.getAllAccounts()[0])
}

activeMsalVersion.addEventCallback((event) => {
  if (
    (event.eventType === EventType.LOGIN_SUCCESS ||
      event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
      event.eventType === EventType.SSO_SILENT_SUCCESS) &&
    event.payload &&
    'account' in event.payload &&
    event.payload.account
  ) {
    activeMsalVersion.setActiveAccount(event.payload.account)
  }
})

activeMsalVersion.addEventCallback((event) => {
  if (
    (event.eventType === EventType.LOGIN_SUCCESS || event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) &&
    event.payload &&
    'account' in event.payload &&
    event.payload.account
  ) {
    if ('idTokenClaims' in event.payload && event.payload.idTokenClaims) {
      if ('tfp' in event.payload.idTokenClaims && event.payload.idTokenClaims['tfp']) {
        const eventIdTokenClaims = event.payload.idTokenClaims

        // User was signed-in before, comes from 'Edit-Profile' Policy:
        if (eventIdTokenClaims['tfp'] === b2cPolicies.names.editProfile) {
          // retrieve the account from initial sign-in to the app
          const originalSignInAccount = activeMsalVersion
            .getAllAccounts()
            .find(
              (account) =>
                account.idTokenClaims &&
                eventIdTokenClaims &&
                'oid' in eventIdTokenClaims &&
                account.idTokenClaims?.oid === eventIdTokenClaims.oid &&
                'sub' in eventIdTokenClaims &&
                account.idTokenClaims.sub === eventIdTokenClaims.sub &&
                account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn,
            )

          const signUpSignInFlowRequest = {
            authority: b2cPolicies.authorities.signUpSignIn.authority,
            account: originalSignInAccount,
          }
          // silently login again with the signUpSignIn policy
          activeMsalVersion.ssoSilent(signUpSignInFlowRequest)
        }

        // User was signed-in before, comes from 'Forgot-Password' Policy:
        if (eventIdTokenClaims['tfp'] === b2cPolicies.names.forgotPassword) {
          const signUpSignInFlowRequest = {
            authority: b2cPolicies.authorities.signUpSignIn.authority,
            scopes: claims,
          }
          activeMsalVersion.loginRedirect(signUpSignInFlowRequest)
        }
      }
    }
  }

  // User was not signed-in before, comes from 'Forgot-Password' Policy:
  if (event.eventType === EventType.LOGIN_FAILURE) {
    if (event.error && 'errorMessage' in event.error && event.error.errorMessage.includes('AADB2C90118')) {
      const resetPasswordRequest = {
        authority: b2cPolicies.authorities.forgotPassword.authority,
        scopes: [],
      }
      activeMsalVersion.loginRedirect(resetPasswordRequest)
    }
  }
})
