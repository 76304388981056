import { memo, PropsWithChildren, ReactNode } from 'react'

import { alpha, Box } from '@mui/material'
import { Outlet } from 'react-router-dom'

type ContentLayoutProps = {
  HeaderSlot: ReactNode
}

const ContentRaw = ({ children, HeaderSlot }: PropsWithChildren<ContentLayoutProps>) => {
  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        overflow: 'auto',
        width: '100%',
        maxWidth: (theme) => theme.breakpoints.values.xl,
        mx: 'auto',
      }}
    >
      <Box
        sx={{
          position: 'relative',
          height: 80,
          mr: 1,
          pt: 0.5,
        }}
        component="header"
      >
        {HeaderSlot}
        <Box
          sx={{
            width: '100%',
            height: 8,
            position: 'absolute',
            top: '100%',
            left: 0,
            background: (theme) =>
              `linear-gradient(180deg, ${alpha(theme.palette.background.default, 1)} 0%, ${alpha(
                theme.palette.background.default,
                0,
              )} 100%)`,
            zIndex: 1,
          }}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          overflow: 'auto',
          pt: 2,
          pb: 1,
          pr: 1,
        }}
      >
        {children || <Outlet />}
      </Box>
    </Box>
  )
}

export const Content = memo(ContentRaw)
