import { create } from 'zustand'
import { persist } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'

import { Identifier } from 'types/localStorage'

export enum ThemeTypes {
  LIGHT = 'light',
  DARK = 'dark',
}

export enum FontTypes {
  ROBOTO = 'Roboto',
  FIRA_SANS = 'Fira Sans',
}

const Languages = {
  En: 'en',
  De: 'de',
} as const

type SettingsDefaults = {
  theme: ThemeTypes
  fontFamily: FontTypes
  sidebarPinned: boolean
  language: (typeof Languages)[keyof typeof Languages]
}

type SettingsSetters = {
  setTheme: (theme: ThemeTypes) => void
  setFontFamily: (theme: FontTypes) => void
  setSidebarPinned: (state: boolean) => void
  setLanguage: (language: (typeof Languages)[keyof typeof Languages]) => void
}

export const useSettingsStore = create<SettingsDefaults & SettingsSetters>()(
  persist(
    immer((set) => ({
      theme:
        window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
          ? ThemeTypes.DARK
          : ThemeTypes.LIGHT,
      fontFamily: FontTypes.ROBOTO,
      sidebarPinned: true,
      language: 'en',
      setTheme: (theme) =>
        set((state) => {
          state.theme = theme

          // This is relevant to have the correct color scheme on application initialization
          localStorage.setItem(Identifier.COLOR_SCHEME, theme)
        }),
      setFontFamily: (newState) => set((state) => void (state.fontFamily = newState)),
      setSidebarPinned: (newState) => set((state) => void (state.sidebarPinned = newState)),
      setLanguage: (newState) => set((state) => void (state.language = newState)),
    })),
    {
      name: Identifier.SETTINGS,
    },
  ),
)

export const selectTheme = (state: SettingsDefaults) => state.theme
export const selectFontFamily = (state: SettingsDefaults) => state.fontFamily
export const selectSidebarPinned = (state: SettingsDefaults) => state.sidebarPinned

export const selectSettingsSetters = (state: SettingsSetters) => ({
  setTheme: state.setTheme,
  setFontFamily: state.setFontFamily,
  setSidebarPinned: state.setSidebarPinned,
  setLanguage: state.setLanguage,
})
