import { useMemo } from 'react'

import { useQuery } from '@tanstack/react-query'
import { matchPath } from 'react-router-dom'

import { StaticRouteParameter, DynamicRouteParameterWPrefix } from 'config/routing'
import { useInvitationContext } from 'domains/ProcessInvitation'
import { environmentQueryKeys, organizationQueryKeys, workspaceQueryKeys } from 'services/queryKeys'

export const useEntityCheck = () => {
  const invitationContextData = useInvitationContext()
  const { invitationId, referrer } = invitationContextData

  const resolvedWorkspaceReferrer = useMemo(
    () =>
      matchPath(
        {
          path: `/${StaticRouteParameter.ENVIRONMENTS}/${DynamicRouteParameterWPrefix.ENVIRONMENT_ID}/${StaticRouteParameter.WORKSPACES}/${DynamicRouteParameterWPrefix.WORKSPACE_ID}`,
        },
        referrer,
      ),
    [referrer],
  )
  const workspaceId = resolvedWorkspaceReferrer?.params.workspaceId

  const resolvedEnvironmentReferrer = useMemo(
    () =>
      matchPath(
        { path: `/${StaticRouteParameter.ENVIRONMENTS}/${DynamicRouteParameterWPrefix.ENVIRONMENT_ID}` },
        referrer,
      ),
    [referrer],
  )
  const environmentId = resolvedEnvironmentReferrer?.params.environmentId

  const resolvedOrganizationReferrer = useMemo(
    () =>
      matchPath(
        {
          path: `/${StaticRouteParameter.MY}/${StaticRouteParameter.ORGANIZATIONS}/${DynamicRouteParameterWPrefix.ORGANIZATION_ID}`,
          end: true,
        },
        referrer,
      ),
    [referrer],
  )
  const organizationId = resolvedOrganizationReferrer?.params.organizationId

  const workspaceInvitationQuery = useQuery({
    ...workspaceQueryKeys.invitationDetail({
      invitationId,
      workspaceId: workspaceId!,
    }),
    enabled: !!resolvedWorkspaceReferrer && !!workspaceId?.length,
  })

  const environmentInvitationQuery = useQuery({
    ...environmentQueryKeys.invitationDetail({
      invitationId,
      environmentId: environmentId!,
    }),
    enabled: !!resolvedEnvironmentReferrer && !!environmentId?.length,
  })

  const organizationInvitationQuery = useQuery({
    ...organizationQueryKeys.invitationDetail({
      invitationId,
      organizationId: organizationId!,
    }),
    enabled: !!resolvedOrganizationReferrer && !!organizationId?.length,
  })

  const type = resolvedWorkspaceReferrer ? 'Workspace' : resolvedEnvironmentReferrer ? 'Environment' : 'Organization'

  return {
    type,
    invitationQuery:
      type === 'Workspace'
        ? workspaceInvitationQuery
        : type === 'Environment'
          ? environmentInvitationQuery
          : organizationInvitationQuery,
    resolvedReferrer:
      type === 'Workspace'
        ? resolvedWorkspaceReferrer!
        : type === 'Environment'
          ? resolvedEnvironmentReferrer!
          : resolvedOrganizationReferrer!,
  } as const
}
