import 'simplebar-react/dist/simplebar.min.css'

import 'setup/i18n'
import 'setup/sentry'

// eslint-disable-next-line import/order, import/first
import { StrictMode } from 'react'
import { MsalProvider } from '@azure/msal-react'
import { CssBaseline } from '@mui/material'
import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { createRoot } from 'react-dom/client'
import { RouterProvider } from 'react-router-dom'

import { ErrorBoundary } from '@shared/components/ErrorHandling'
import { ThemeProvider } from '@shared/components/ThemeProvider'
import { router } from 'pages/AppInit'
import { activeMsalVersion } from 'setup/azureB2C'
import { queryClient } from 'setup/react-query'

const rootElement = document.getElementById('root')
const reactRoot = createRoot(rootElement!)
reactRoot.render(
  <StrictMode>
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} position="bottom" />
      <ThemeProvider>
        <MsalProvider instance={activeMsalVersion}>
          <ErrorBoundary>
            <CssBaseline enableColorScheme />
            <RouterProvider router={router} />
          </ErrorBoundary>
        </MsalProvider>
      </ThemeProvider>
    </QueryClientProvider>
  </StrictMode>,
)
