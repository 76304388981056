import { memo, PropsWithoutRef, ReactElement } from 'react'

import { KeyboardArrowRight, Warning } from '@mui/icons-material'
import { Box, Breadcrumbs as MUIBreadcrumbs, Skeleton, styled, SxProps, Typography } from '@mui/material'
import { Link, To } from 'react-router-dom'

const Root = styled(Box)({
  display: 'flex',
  alignItems: 'center',
})

export type BreadcrumbPart = {
  isLoading?: boolean
  to?: To
  icon?: ReactElement
} & (
  | {
      title?: string
      key: string
    }
  | {
      title: string
      key?: never
    }
)

type DynamicBreadcrumbsProps = {
  isInitialLoading?: boolean
  parts?: BreadcrumbPart[]
  sx?: SxProps
}

const DynamicBreadcrumbsRaw = ({ isInitialLoading, parts, sx }: PropsWithoutRef<DynamicBreadcrumbsProps>) => {
  return (
    <Root sx={sx}>
      <MUIBreadcrumbs separator={<KeyboardArrowRight sx={{ mx: -1 }} fontSize="small" />}>
        {isInitialLoading ? (
          <Skeleton sx={{ width: 480 }} />
        ) : (
          parts?.map((item) => (
            <Box sx={{ display: 'flex', alignItems: 'center' }} key={item.title}>
              <Typography
                sx={[
                  {
                    display: 'block',
                    color: 'inherit',
                    textDecoration: 'inherit',
                    maxWidth: 240,
                  },
                  !!item.to && {
                    '&:hover': {
                      textDecoration: 'underline',
                    },
                  },
                ]}
                variant="text"
                noWrap
                {...(item.to && {
                  component: Link,
                  to: item.to,
                })}
              >
                {item.isLoading ? <Skeleton sx={{ width: 80 }} /> : item.title}
              </Typography>
              {item.icon}
            </Box>
          )) || (
            <Typography
              sx={{
                display: 'flex',
                alignItems: 'center',
                columnGap: 1,
                color: (theme) => theme.palette.action.disabled,
              }}
              variant="text-sm"
            >
              <Warning fontSize="inherit" />
              Breadcrumbs yet to be implemented
            </Typography>
          )
        )}
      </MUIBreadcrumbs>
    </Root>
  )
}

export const DynamicBreadcrumbs = memo(DynamicBreadcrumbsRaw)
