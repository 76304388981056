import { memo, useEffect, useRef, useState } from 'react'

import { LinearProgress } from '@mui/material'

type SnackPanelProgressProps = {
  autoHideDuration: number
  pauseProgress?: boolean
}

const SnackPanelProgressRaw = ({ autoHideDuration, pauseProgress }: SnackPanelProgressProps) => {
  const [_, setDuration] = useState(autoHideDuration)
  const [countdownPercentage, setCountdownPercentage] = useState(100)

  const intervalRef = useRef<NodeJS.Timeout | null>()

  useEffect(() => {
    if (pauseProgress) {
      clearInterval(intervalRef.current!)
      intervalRef.current = null
    }
  }, [pauseProgress])

  useEffect(() => {
    if (!pauseProgress) {
      let isResumed = intervalRef.current === null

      intervalRef.current = setInterval(() => {
        setDuration((prev) => {
          // Take half of the time due to library implementation (notistack - SnackbarItem)
          const newTimeDuration = (isResumed ? Math.ceil(autoHideDuration / 2.5) : prev) - 350
          const percentage = Math.ceil((newTimeDuration / autoHideDuration) * 100)
          setCountdownPercentage(percentage)

          if (isResumed) {
            isResumed = false
          }

          if (newTimeDuration === 0) {
            clearInterval(intervalRef.current!)
          }

          return newTimeDuration
        })
      }, 300)

      return () => {
        clearInterval(intervalRef.current!)
      }
    }
  }, [autoHideDuration, pauseProgress])

  return (
    <LinearProgress
      sx={{ position: 'absolute', bottom: 0, left: 0, width: '100%' }}
      variant="determinate"
      value={countdownPercentage}
    />
  )
}

export const SnackPanelProgress = memo(SnackPanelProgressRaw)
