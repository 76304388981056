import { useRef } from 'react'

import { captureException } from '@sentry/react'
import { useMutation } from '@tanstack/react-query'
import { SnackbarKey, useSnackbar } from 'notistack'
import { useShallow } from 'zustand/react/shallow'

import { IdentityAPI } from 'services/api'
import { selectAuthenticationStoreV2Props, useAuthenticationStoreV2 } from 'stores/auth'
import { calculateTokenDelta } from 'utils'

export const useAccessRefreshMutation = () => {
  const { identityAccessToken, identityRefreshToken, setAuthenticationStoreV2PropertyValue } = useAuthenticationStoreV2(
    useShallow(
      selectAuthenticationStoreV2Props(
        'identityAccessToken',
        'identityRefreshToken',
        'setAuthenticationStoreV2PropertyValue',
      ),
    ),
  )

  const snackBarKeyRef = useRef<SnackbarKey>()

  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  return useMutation({
    mutationFn: () =>
      IdentityAPI.refresh.refreshPost(
        {
          tokenRequest: {
            accessToken: identityAccessToken,
            refreshToken: identityRefreshToken,
          },
        },
        { headers: { 'Content-Type': 'application/json' }, credentials: 'include' },
      ),
    onMutate: () => {
      snackBarKeyRef.current = enqueueSnackbar('Refresh credentials', {
        variant: 'info',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
      })
    },
    onSuccess: ({ userId, accessToken, refreshToken }) => {
      if (!accessToken) {
        throw new Error('Access token not found')
      }

      if (!refreshToken) {
        captureException(new Error('Refresh token not present in response'))
      }

      if (!userId) {
        throw new Error('User ID not available')
      }

      setAuthenticationStoreV2PropertyValue('accessDenialDelta', calculateTokenDelta(accessToken))

      setAuthenticationStoreV2PropertyValue('identityUserId', userId)
      setAuthenticationStoreV2PropertyValue('identityAccessToken', accessToken)
      setAuthenticationStoreV2PropertyValue('identityRefreshToken', refreshToken ?? undefined)
    },
    onError: (err) => {
      captureException(err)
    },
    onSettled: () => {
      closeSnackbar(snackBarKeyRef.current)
    },
  })
}

export const useAccessRevokeMutation = () => {
  const { identityAccessToken, identityRefreshToken } = useAuthenticationStoreV2(
    useShallow(selectAuthenticationStoreV2Props('identityAccessToken', 'identityRefreshToken')),
  )

  return useMutation({
    mutationFn: () =>
      IdentityAPI.refresh.revokePost(
        {
          tokenRequest: {
            accessToken: identityAccessToken,
            refreshToken: identityRefreshToken,
          },
        },
        {
          headers: { 'Content-Type': 'application/json' },
          credentials: 'include',
        },
      ),
    onError: (err) => {
      captureException(err)
    },
  })
}
