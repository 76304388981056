import * as React from "react";
const SvgNwLogoSolo = (props) => /* @__PURE__ */ React.createElement("svg", { id: "Layer_1", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", x: "0px", y: "0px", viewBox: "0 0 566.93 566.93", style: {
  enableBackground: "new 0 0 566.93 566.93"
}, xmlSpace: "preserve", ...props }, /* @__PURE__ */ React.createElement("style", { type: "text/css" }, "\n	.st0{fill:url(#SVGID_1_);}\n	.st1{fill:url(#SVGID_2_);}\n	.st2{fill:url(#SVGID_3_);}\n	.st3{fill:url(#SVGID_4_);}\n"), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("linearGradient", { id: "SVGID_1_", gradientUnits: "userSpaceOnUse", x1: 283.0488, y1: 556.5999, x2: 283.0488, y2: -23.7219 }, /* @__PURE__ */ React.createElement("stop", { offset: 0, style: {
  stopColor: "#224686"
} }), /* @__PURE__ */ React.createElement("stop", { offset: 0.4654, style: {
  stopColor: "#398FD0"
} }), /* @__PURE__ */ React.createElement("stop", { offset: 0.9731, style: {
  stopColor: "#8BC2ED"
} })), /* @__PURE__ */ React.createElement("path", { className: "st0", d: "M361.73,24.97c-14.98-4.56-30.51-7.85-46.47-9.75c-10.56-1.26-21.31-1.92-32.21-1.92 c-148.94,0-269.69,120.74-269.69,269.68c0,148.94,120.74,269.68,269.69,269.68c19,0,37.53-1.98,55.42-5.71 c87.68-18.31,159.71-79.2,193.5-160.05c13.37-31.98,20.76-67.09,20.76-103.92C552.73,161.41,472.29,58.64,361.73,24.97z" }), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("linearGradient", { id: "SVGID_2_", gradientUnits: "userSpaceOnUse", x1: 399.8775, y1: -6.7477, x2: 392.4803, y2: 414.8889 }, /* @__PURE__ */ React.createElement("stop", { offset: 0, style: {
  stopColor: "#83BBE9"
} }), /* @__PURE__ */ React.createElement("stop", { offset: 0.1087, style: {
  stopColor: "#8EC1EB"
} }), /* @__PURE__ */ React.createElement("stop", { offset: 0.4885, style: {
  stopColor: "#ADD1F1"
} }), /* @__PURE__ */ React.createElement("stop", { offset: 0.7992, style: {
  stopColor: "#BFDBF5"
} }), /* @__PURE__ */ React.createElement("stop", { offset: 1, style: {
  stopColor: "#C6DFF6"
} })), /* @__PURE__ */ React.createElement("path", { className: "st1", d: "M361.73,24.97c-50.31,27.71-91.97,69.17-119.94,119.32c-2.07,12.07-3.16,24.48-3.16,37.14 c0,120.72,97.87,218.59,218.59,218.59c26.26,0,51.43-4.63,74.75-13.12c13.37-31.98,20.76-67.09,20.76-103.92 C552.73,161.41,472.29,58.64,361.73,24.97z" }), /* @__PURE__ */ React.createElement("linearGradient", { id: "SVGID_3_", gradientUnits: "userSpaceOnUse", x1: 367.591, y1: 627.8896, x2: 367.591, y2: 206.7588 }, /* @__PURE__ */ React.createElement("stop", { offset: 0, style: {
  stopColor: "#224686"
} }), /* @__PURE__ */ React.createElement("stop", { offset: 0.4654, style: {
  stopColor: "#398FD0"
} }), /* @__PURE__ */ React.createElement("stop", { offset: 0.9731, style: {
  stopColor: "#8BC2ED"
} })), /* @__PURE__ */ React.createElement("path", { className: "st2", d: "M531.97,386.9c-23.32,8.49-48.5,13.12-74.75,13.12c-120.72,0-218.59-97.87-218.59-218.59 c0-12.66,1.09-25.07,3.16-37.14c-24.56,44.04-38.57,94.76-38.57,148.76c0,105.75,53.67,198.97,135.26,253.9 C426.14,528.64,498.18,467.76,531.97,386.9z" }), /* @__PURE__ */ React.createElement("linearGradient", { id: "SVGID_4_", gradientUnits: "userSpaceOnUse", x1: 187.5461, y1: 556.5999, x2: 187.5461, y2: -23.7216 }, /* @__PURE__ */ React.createElement("stop", { offset: 0, style: {
  stopColor: "#203870"
} }), /* @__PURE__ */ React.createElement("stop", { offset: 0.4428, style: {
  stopColor: "#1468AF"
} }), /* @__PURE__ */ React.createElement("stop", { offset: 0.8112, style: {
  stopColor: "#77ABDE"
} }), /* @__PURE__ */ React.createElement("stop", { offset: 1, style: {
  stopColor: "#BFD5E7"
} })), /* @__PURE__ */ React.createElement("path", { className: "st3", d: "M203.21,293.06c0-54,14.01-104.72,38.57-148.76c27.97-50.15,69.63-91.61,119.94-119.32 c-14.98-4.56-30.51-7.85-46.47-9.75c-10.56-1.26-21.31-1.92-32.21-1.92c-148.94,0-269.68,120.74-269.68,269.68 s120.74,269.68,269.68,269.68c19,0,37.53-1.98,55.42-5.71C256.88,492.02,203.21,398.81,203.21,293.06z" }))));
export default SvgNwLogoSolo;
