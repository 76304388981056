import { useCallback, useEffect, useLayoutEffect } from 'react'

import { AccountInfo } from '@azure/msal-browser'
import { useQuery } from '@tanstack/react-query'
import { useShallow } from 'zustand/react/shallow'

import { authQueryKeys } from 'services/queryKeys'
import { useAuthenticationStoreV2, selectAuthenticationStoreV2Props } from 'stores/auth'

type UseADB2CSignInProps = {
  enabled?: boolean
}

export const useADB2CSignIn = ({ enabled = true }: UseADB2CSignInProps) => {
  const { setAuthenticationStoreV2PropertyValue } = useAuthenticationStoreV2(
    useShallow(selectAuthenticationStoreV2Props('setAuthenticationStoreV2PropertyValue')),
  )

  const redirectQuery = useQuery(authQueryKeys.handleRedirect())

  const providerQuery = useQuery({
    ...authQueryKeys.requestAzureAdAccess(),
    enabled: redirectQuery.isSuccess && redirectQuery.data === null && !!enabled,
  })

  useLayoutEffect(() => {
    if (redirectQuery.isSuccess && redirectQuery.data !== null) {
      setAuthenticationStoreV2PropertyValue('providerIdToken', redirectQuery.data.idToken)
      setAuthenticationStoreV2PropertyValue('providerAccessToken', redirectQuery.data.accessToken)
    }
  }, [redirectQuery.data, redirectQuery.isSuccess, setAuthenticationStoreV2PropertyValue])

  useLayoutEffect(() => {
    if (providerQuery.isSuccess && providerQuery.data) {
      setAuthenticationStoreV2PropertyValue('providerIdToken', providerQuery.data.idToken)
      setAuthenticationStoreV2PropertyValue('providerAccessToken', providerQuery.data.accessToken)
    }
  }, [providerQuery.data, providerQuery.isSuccess, setAuthenticationStoreV2PropertyValue])

  const handleUserManagement = useCallback(
    (account: AccountInfo | null) => {
      const idTokenClaims = account?.idTokenClaims
      if (!idTokenClaims) throw new Error('No claims available')

      const accountEmailAddresses = [
        ...new Set(
          [idTokenClaims.email, ...((idTokenClaims.otherMails as string[] | undefined) ?? [])].filter(
            Boolean,
          ) as string[],
        ),
      ]
      const displayName =
        (idTokenClaims.display_name as string | undefined) || (idTokenClaims.name as string | undefined)
      const firstName = idTokenClaims.given_name as string | undefined
      const lastName = idTokenClaims.family_name as string | undefined
      const isSocial = !!idTokenClaims.idp

      setAuthenticationStoreV2PropertyValue('emailAdresses', accountEmailAddresses)
      setAuthenticationStoreV2PropertyValue('socialSignInMethod', isSocial)

      if (displayName) {
        setAuthenticationStoreV2PropertyValue('displayName', displayName)
      }

      if (firstName) {
        setAuthenticationStoreV2PropertyValue('firstName', firstName)
      }

      if (lastName) {
        setAuthenticationStoreV2PropertyValue('lastName', lastName)
      }
    },
    [setAuthenticationStoreV2PropertyValue],
  )

  useEffect(() => {
    if (redirectQuery.isSuccess && redirectQuery.data) {
      const { account } = redirectQuery.data

      handleUserManagement(account)
    }
  }, [handleUserManagement, redirectQuery.data, redirectQuery.isSuccess])

  useEffect(() => {
    if (providerQuery.isSuccess && providerQuery.data) {
      const { account } = providerQuery.data

      handleUserManagement(account)
    }
  }, [handleUserManagement, providerQuery.data, providerQuery.isSuccess, redirectQuery.data, redirectQuery.isSuccess])

  return { redirectQuery, providerQuery }
}
